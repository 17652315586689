import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "../../../components/ContactCard/ContactCard";
import { getCurrentUser } from "../../../services/authService";
import styles from "./Productivity.module.css";
const contacts = [
  {
    Name: "Heba Elbishbeashy",
    Title: "Team Leader, Productivity CoE",
    // Area: "TSS Digitalization COE",
    Location: "Smart Village, VHUB2, 2nd Floor",
    Email: "heba.elbishbeashy@vodafone.com",
    Phone: "+2 01024893727",
  },
];
function ProductivityContactUs() {
  const history = useNavigate();
  React.useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/ProductivityContactUs`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  return (
    <div className='container-fluid '>
      <div className=' row'>
        <div
          className={styles["btn-back-envirtual-container"] + " col-md-12"}
          onClick={() => {
            history("/showroom/productivity");
          }}
        >
          <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
          <label className={styles["back-btn-label"]}>Back</label>
        </div>
        <img
          src='/assets/showroom/mainhall/ic-bg14.png'
          className='mentor-card-screen-background'
          alt=''
        />
        <div className='col-md-3'></div>
        {contacts.map((e) => (
          <div className='col-md-6 p-5 col-sm-12'>
            <ContactCard cardData={e}></ContactCard>
          </div>
        ))}
        <div className='col-md-3'></div>
      </div>
    </div>
  );
}

export default ProductivityContactUs;
