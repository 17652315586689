import React, { useEffect, useState } from "react";
import styles from "./WIVAConfigPage.module.css";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import axios from "axios";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-toggle/style.css";
// import * as XLSX from "xlsx";
// import * as _ from "lodash";
import Toggle from "react-toggle";
import ReactPaginate from "react-paginate";
function WIVAConfigPage() {
  const [configTable, setconfigTable] = React.useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [exported, setexported] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [DisplayOrder, setdisplayOrder] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [id, setID] = useState("");
  const [configDate, setDateChange] = useState(new Date());
  const [isLiveEvent, setIsLiveEvent] = useState(true);
  const [NotLiveurl, setNotLiveurl] = useState("");
  const [summary, setSummary] = useState("");
  useEffect(() => {
    getConfig();
  }, []);
  const getConfig = () => {
    setconfigTable([]);
    setexported([]);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}wivaconfig/getallconfigpaginated`,
        {
          page: pageNumber,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setconfigTable(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setLoading(false);
      });
  };
  const handlePaginationClick = (e) => {
    setconfigTable([]);
    setexported([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}wivaconfig/getallconfigpaginated`,
        {
          page: e.selected,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setconfigTable(res.data.list);
        setTotalCount(res.data.paging.totalCount);
      });
  };
  // const exportconfig = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_SERVER_URL}wivaconfig/getallconfig`, {})
  //     .then((res) => {
  //       setexported(res.data);
  //       downloadExcel(res.data);
  //     });
  // };
  // const downloadExcel = (dataObj) => {
  //   const data = dataObj.map((record) => {
  //     ;
  //     let obj = {};
  //     for (let key in record) {
  //       if (_.startCase(key) != "Id" && _.startCase(key) != "V") {
  //         obj[_.startCase(key)] = record[key];
  //       }
  //     }

  //     return obj;
  //   });
  //   const ws = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
  //   const wb = (XLSX.WorkBook = XLSX.utils.book_new());
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   // var staffId = +localStorage.getItem('StaffId');

  //   /* save to file */
  //   XLSX.writeFile(wb, "WivaConfig_" + " " + new Date() + ".xlsx");
  // };
  const uploadfile = (e, config) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivaconfig/uploadAssets`, {
          imageBytes: reader.result,
          configName: config,
          // userName: auth.getCurrentUser().preferred_username.split(".")[0],
          eventName: "wiv",
        })
        .then((r) => {
          getConfig();
        });
    };
  };
  const [selected, SetselectedID] = React.useState("");
  const deleteConfig = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivaconfig/deleteconfig`, {
        id: selected,
      })
      .then((x) => {
        getConfig();
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='wivadeleteall'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Delete Config
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Are you sure you want to delete This Config ?</p>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => {
                  SetselectedID("");
                }}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={deleteConfig}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='wivaAdd'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div
              className='modal-body row'
              style={{
                display: "flex",

                alignItems: "flex-start",
                margin: "2rem",
              }}
            >
              <div className='col-md-6'>
                <label className={styles["label"]}>Config Name</label>
                {name != "main session" ? (
                  <input
                    value={name}
                    placeholder='Config Name(not used anywhere)'
                    className={styles["joinInput"] + " form-control mb-2"}
                    type='text'
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                ) : (
                  <input
                    value={name}
                    placeholder='Config Name(not used anywhere)'
                    className={styles["joinInput"] + " form-control mb-2"}
                    type='text'
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    disabled={true}
                  />
                )}
              </div>
              <div className='col-md-6'>
                <label className={styles["label"]}>Config Value</label>
                <input
                  value={link}
                  placeholder='Link'
                  className={styles["joinInput"] + " form-control mb-2"}
                  type='text'
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
              {name != "main session" ? (
                <>
                  {" "}
                  <div className='col-md-6'>
                    <label className={styles["label"]}>Display Order</label>
                    <input
                      value={DisplayOrder}
                      placeholder='Room Name'
                      className={styles["joinInput"] + " form-control mb-2"}
                      type='text'
                      onChange={(e) => {
                        setdisplayOrder(e.target.value);
                      }}
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className={styles["label"]}> Config Category</label>
                    <div class='dropdown mb-2'>
                      <button
                        class='btn btn-secondary dropdown-toggle'
                        type='button'
                        id='dropdownMenuButton'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        className={styles["ddl-btn"]}
                      >
                        {category}
                        <i class='fa fa-caret-down pl-5'></i>
                      </button>
                      <div
                        class='dropdown-menu'
                        aria-labelledby='dropdownMenuButton'
                      >
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Espresso");
                          }}
                        >
                          Espresso
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Other Collabs");
                          }}
                        >
                          Other Collabs
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Empowering Together");
                          }}
                        >
                          Empowering Together
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Women in _Vois");
                          }}
                        >
                          Women in _Vois
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Women Around the World");
                          }}
                        >
                          Women Around the World
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Masterclass");
                          }}
                        >
                          Masterclass
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("Main Session");
                          }}
                        >
                          Main Session
                        </a>
                        <a
                          class='dropdown-item'
                          onClick={() => {
                            setCategory("News Letter");
                          }}
                        >
                          News Letter
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {category == "Main Session" ? (
                <>
                  {" "}
                  <div className='col-md-6'>
                    <label className={styles["label"]}>Event Date</label>
                    <div class=' mb-2'>
                      <DatePicker onChange={setDateChange} value={configDate} />
                    </div>
                  </div>{" "}
                  <div className='col-md-6'>
                    <label className={styles["label"]}>Event Date & Time</label>
                    <input
                      value={summary}
                      placeholder='30th April, 12pm UK Time'
                      className={styles["joinInput"] + " form-control mb-2"}
                      type='text'
                      onChange={(e) => {
                        setSummary(e.target.value);
                      }}
                    />
                  </div>
                  <div className={styles["toggle"] + " col-md-6"}>
                    <label className={styles["label"]}>Is LiveEvent?</label>
                    <Toggle
                      id='cheese-status'
                      defaultChecked={isLiveEvent}
                      onChange={(e) => {
                        setIsLiveEvent(e.target.checked);
                        if (e.target.checked) setNotLiveurl("");
                      }}
                    />
                  </div>
                  {isLiveEvent == false ? (
                    <div className='col-md-6'>
                      <label className={styles["label"]}>Banner Link</label>
                      <input
                        value={NotLiveurl}
                        placeholder='Link'
                        className={styles["joinInput"] + " form-control mb-2"}
                        type='text'
                        onChange={(e) => {
                          setNotLiveurl(e.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {category == "News Letter" ? (
                <>
                  <div className={styles["toggle"] + " col-md-6"}>
                    <label className={styles["label"]}>Is Latest?</label>
                    <Toggle
                      id='cheese-status'
                      defaultChecked={isLiveEvent}
                      onChange={(e) => {
                        debugger;
                        setIsLiveEvent(e.target.checked);
                        if (e.target.checked) setNotLiveurl("");
                      }}
                    />
                  </div>
                </>
              ) : null}
              <div
                className='col-md-12'
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  className='btn btn-danger'
                  onClick={(e) => {
                    if (link != "" && name != "" && category != "")
                      if (isEdit) {
                        axios
                          .post(
                            `${process.env.REACT_APP_SERVER_URL}wivaconfig/editconfig`,
                            {
                              summary,
                              bannerLink: NotLiveurl,
                              isLive: isLiveEvent,
                              eventDate: configDate,
                              configName: name,
                              configVal: link,
                              displayOrder: DisplayOrder,
                              configCat: category,
                              id: id,
                            }
                          )
                          .then((x) => {
                            setName("");
                            setLink("");
                            setCategory("");
                            setID("");
                            setdisplayOrder(0);
                            setEdit(false);
                            setDateChange(new Date());
                            setSummary("");
                            setNotLiveurl("");
                            setIsLiveEvent(true);
                            getConfig();
                          });
                      } else {
                        axios
                          .post(
                            `${process.env.REACT_APP_SERVER_URL}wivaconfig/addconfig`,
                            {
                              summary,
                              configName: name,
                              configVal: link,
                              displayOrder: DisplayOrder,
                              configCat: category,
                              bannerLink: NotLiveurl,
                              isLive: isLiveEvent,
                              eventDate: configDate,
                            }
                          )
                          .then((x) => {
                            setName("");
                            setLink("");
                            setCategory("");
                            setID("");
                            setdisplayOrder(0);
                            setDateChange(new Date());
                            setNotLiveurl("");
                            setIsLiveEvent(true);
                            setEdit(false);
                            setSummary("");
                            getConfig();
                          });
                      }
                  }}
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid p-0'>
        <WivaNavBar></WivaNavBar>
        <div className={styles["joinInnerContainer"] + " row"}>
          <div className={styles["config-table-header"] + " col-md-12"}>
            Config Table
            <div className={styles["actions-btn-div"]}>
              <button
                className={styles["drop-down-btn"] + " btn mr-2"}
                data-toggle='modal'
                data-target='#wivaAdd'
                id='wivaAddbtn'
              >
                Add Configuration
              </button>
              {/* <button
                className={styles["drop-down-btn"] + " btn "}
                onClick={exportconfig}
              >
                Export To Excel
              </button> */}
            </div>
          </div>
          {loading ? (
            <div class='spinner-border text-danger' role='status'>
              <span class='sr-only'>Loading...</span>
            </div>
          ) : (
            <div className={"col-md-12"}>
              <table className='table table-striped table-dark'>
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>Key</th>
                    <th style={{ width: "50%" }}>Value</th>
                    <th style={{ width: "25%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {configTable.map((el, i) => {
                    return (
                      <tr>
                        <th scope='row'>{el.configKey}</th>
                        <td
                          style={{ maxWidth: "20rem", wordBreak: "break-all" }}
                        >
                          {el.configType == "bool" ? el.islive : el.configValue}
                        </td>
                        <td>
                          <div className={styles["table-icons"]}>
                            {el.configType == "file" ? (
                              <>
                                {" "}
                                <i
                                  class='fas fa-save'
                                  onClick={() => {
                                    document
                                      .getElementById(`fileUpload-${i}`)
                                      .click();
                                  }}
                                ></i>
                                <input
                                  type='file'
                                  onChange={(e) => {
                                    uploadfile(e, el.configKey);
                                  }}
                                  style={{ display: "none" }}
                                  id={`fileUpload-${i}`}
                                />
                              </>
                            ) : el.configType == "bool" ? (
                              <Toggle
                                id='cheese-status'
                                defaultChecked={el.islive}
                                onChange={(e) => {
                                  axios
                                    .post(
                                      `${process.env.REACT_APP_SERVER_URL}wivaconfig/editboolconfig`,
                                      {
                                        id: el._id,
                                        isLive: e.target.checked,
                                      }
                                    )
                                    .then((x) => {
                                      getConfig();
                                    });
                                }}
                              />
                            ) : (
                              <>
                                <i
                                  class='fa fa-pencil'
                                  onClick={() => {
                                    setCategory(el.configGroup);
                                    setID(el._id);
                                    setEdit(true);
                                    setName(el.configKey);
                                    setLink(el.configValue);
                                    setdisplayOrder(el.displayOrder);
                                    setDateChange(el.eventDate);
                                    setNotLiveurl(el.eventbanner);
                                    setSummary(el.summary);
                                    setIsLiveEvent(el.islive);
                                  }}
                                  data-toggle='modal'
                                  data-target='#wivaAdd'
                                ></i>
                                {el.configKey != "main session" ? (
                                  <i
                                    class='fa fa-trash ml-2'
                                    onClick={() => {
                                      SetselectedID(el._id);
                                    }}
                                    data-toggle='modal'
                                    data-target='#wivadeleteall'
                                  ></i>
                                ) : null}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    onPageChange={(e) => {
                      handlePaginationClick(e); 
                      setPageNumber(e.selected);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default WIVAConfigPage;
