import React, { useState, useRef } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCurrentUser } from "../../../services/authService";
import styles from "./ProjectsCatalogue.module.css";
function ProjectsCatalogue() {
  const history = useNavigate();
  React.useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/ProjectsCatalogue`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: "/assets/showroom/se/SE-PROJECTS.pdf",
    page,
    canvasRef,
  });

  return (
    <div className='container-fluid '>
      <div className=' row'>
        <div
          className={styles["btn-back-envirtual-container"] + " col-md-12"}
          onClick={() => {
            history("/showroom/seprojectshall");
          }}
        >
          <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
          <label className={styles["back-btn-label"]}>Back</label>
        </div>
        <img
          src='/assets/showroom/mainhall/ic-bg14.png'
          className='mentor-card-screen-background'
          alt=''
        />
        <div className='col-md-1'></div>
        <div className='col-md-10 col-sm-12'>
          <div className='magazine'>
            <button
              className='btn btn-outline-light m-5'
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              Previous
            </button>
            {!pdfDocument && <span>Loading...</span>}

            <canvas
              ref={canvasRef}
              width='50%'
              height='50%'
              style={{ height: "50%", width: "100%" }}
            />
            {Boolean(pdfDocument && pdfDocument.numPages) && (
              <button
                className='btn btn-outline-light m-5 pr-4 pl-4'
                disabled={page === pdfDocument.numPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            )}
          </div>
        </div>
        <div className='col-md-1'></div>
      </div>
    </div>
  );
}

export default ProjectsCatalogue;
