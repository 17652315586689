import React from "react";
import * as Icon from "react-bootstrap-icons";
import styles from "./ContactCard.module.css";
function ContactCard({ cardData }) {
  return (
    <>
      <div className={styles["flip"]}>
        <div
          className={styles["front"]}
          style={{
            backgroundImage: 'url("/assets/cardBG.png")',
            boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
            border: "3px solid white",
          }}
        >
          <h1 className={styles["text-shadow"]}>{cardData.Name}</h1>

          <h3 className={styles["text-shadow"]} style={{ display: "block" }}>
            {cardData.Title}
          </h3>
        </div>
        <div className={styles["back"]}>
          <h4 className={styles["text-shadow"]}>
            {" "}
            <Icon.Envelope color='white' className='mr-2' /> {cardData.Email}
          </h4>

          <h4 className={styles["text-shadow"]}>
            <Icon.Phone color='white' className='mr-2' />
            {cardData.Phone}
          </h4>
          {/* 
          <h4 className='text-shadow'>
            {" "}
            <Icon.ArrowDownSquareFill color='white' className='mr-2' />
            {cardData.Area}
          </h4> */}

          <h4 className='text-shadow'>
            {" "}
            <Icon.PinMapFill color='white' className='mr-2' />
            {cardData.Location}
          </h4>
        </div>
      </div>
    </>
  );
}

export default ContactCard;
