import React, { useEffect } from "react";
import "./mosaicWall.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const MosaicWall = () => {
  const [imageLinks, setImageLinks] = React.useState([]);
  const [imageBytes, setImageBytes] = React.useState([]);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}mosaicWall/${localStorage.getItem(
          "selectedEventName"
        )}`
      )
      .then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            imageLinks.push(res.data[i]);

            axios.get(`${res.data[i]}`).then((res) => {
              imageBytes.push(res.data);
              if (imageLinks.length === imageBytes.length) {
                setLoadBytes(true);
              }
            });
          }
        }
      });
  }, []);

  const imageContainer = () => {
    return imageBytes.map((img) => {
      if (imageBytes.length <= 12) {
        return (
          <img
            src={img}
            className={`col-md-${Math.ceil(12 / imageBytes.length)} m-0 p-0`}
            height='50%'
          />
        );
      } else {
        return (
          <img
            src={img}
            className={`col-md-${Math.floor(imageBytes.length / 12)} m-0 p-0`}
            height='50%'
          />
        );
      }
    });
  };

  return (
    <div className='container-fluid'>
      <Link
        type='button'
        className='btn btn-light mb-2 '
        style={{ position: "fixed", right: 15, top: 5 }}
        to={
          localStorage.selectedEventName == "emm"
            ? "emm/beanbags"
            : "/breakroom"
        }
      >
        {localStorage.selectedEventName == "emm"
          ? "Back to waiting area"
          : "Back to Fun Area"}
      </Link>
      <img
        className='photobooth-img'
        src='../assets/photobooth-background.jpg'
        alt=''
      />
      <div className='row col-xs-12 d-flex justify-content-center'>
        <h1 className='text-light '>Mosaic Wall</h1>
      </div>
      {loadBytes === true ? (
        <>
          <div className='mosaic-wall-images row'>{imageContainer()}</div>
        </>
      ) : (
        <div className='text-center'>
          <div className='spinner-grow text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-secondary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-success' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-danger' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-warning' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-info' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-light' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
          <div className='spinner-grow text-dark' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MosaicWall;
