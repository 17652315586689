import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getJwt } from "../../services/authService";
import { setJwt } from "../../services/httpService";

function Entrance() {
  const history = useNavigate();
  useEffect(() => {
    getJwt().then((r) => {
      if (r) setJwt();
      else history("/");
    });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src='/assets/Loading_icon.gif'></img>
    </div>
  );
}

export default Entrance;
