import React, { useEffect, useState } from "react";
import styles from "./WIVACommunityMembers.module.css";
import axios from "axios";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
function WIVACommunityMembers() {
  const [membersTable, setMembersTable] = React.useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getConfig();
  }, []);
  const getConfig = () => {
    setMembersTable([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}wivasessionparts/getallpaginated`,
        {
          page: pageNumber,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setMembersTable(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setLoading(false);
        setPageNumber(0);
      });
  };
  const handlePaginationClick = (e) => {
    setMembersTable([]);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}wivasessionparts/getallpaginated`,
        {
          page: e.selected,
          pageCount: pageSize,
        }
      )
      .then((res) => {
        setMembersTable(res.data.list);
        setTotalCount(res.data.paging.totalCount);
      });
  };
  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        if (
          !element[0].startsWith("+") &&
          !element[0].startsWith("=") &&
          !element[0].startsWith("-")
        ) {
          let em = emails;
          if (element) {
            em.push(element[0]);
            setEmails(em);
          }
        }
      }
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}wivasessionparts/`, {
          emails: emails,
        })
        .then((res) => {
          getConfig();
        });
    };
    reader.readAsBinaryString(f);
  };
  const deleteall = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}wivasessionparts/deleteall`, {})
      .then((res) => {
        getConfig();
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='wivadeleteall'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Delete All
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Are you sure you want to delete all subscribers ?</p>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={deleteall}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["config-table-header"] + " col-md-12"}>
        Subscribers
        <div className={styles["actions-btn-div"]}>
          <button
            className={styles["drop-down-btn"] + " btn mr-2"}
            data-toggle='modal'
            data-target='#wivadeleteall'
            id='wivaAddbtn'
          >
            Delete All
          </button>
          <button
            className={styles["drop-down-btn"] + " btn mr-2"}
            data-toggle='modal'
            data-target='#wivaAdd'
            id='wivaAddbtn'
            onClick={() => {
              document.getElementById("subscribers").click();
            }}
          >
            Upload Subscribers
          </button>
          <input
            id='subscribers'
            type='file'
            className='d-none'
            onChange={handleUpload}
          ></input>
          <a
            href='/WIVA Subscribers.xlsx'
            download='WIVA Subscribers'
            target='_blank'
          >
            <button
              className={styles["drop-down-btn"] + " btn mr-2"}
              data-toggle='modal'
              data-target='#wivaAdd'
              id='wivaAddbtn'
            >
              Download Subscribers sheet
            </button>
          </a>
          {/* <button
                className={styles["drop-down-btn"] + " btn "}
                onClick={exportconfig}
              >
                Export To Excel
              </button> */}
        </div>
      </div>

      {loading ? (
        <div class='spinner-border text-danger' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      ) : (
        <div className={"col-md-12"}>
          <div className='row'>
            {membersTable.map((el, i) => {
              return (
                <div className={" col-md-3"}>
                  <div className={styles["subscriber-card"]}>
                    <span className={styles["subscriber-card-title"]}>
                      {el.email}
                    </span>

                    <i
                      class='fa fa-trash ml-2'
                      style={{
                        cursor: "pointer",
                        color: "#e60000",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        axios
                          .post(
                            `${process.env.REACT_APP_SERVER_URL}wivasessionparts/deleteone`,
                            {
                              id: el._id,
                            }
                          )
                          .then((x) => {
                            getConfig();
                          });
                      }}
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            pageCount={Math.ceil(totalCount / pageSize)}
            onPageChange={handlePaginationClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </>
  );
}

export default WIVACommunityMembers;
