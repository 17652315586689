import React, { useEffect } from "react";
import styles from "./EarthAr.module.css";
import { round } from "@amcharts/amcharts4/.internal/core/utils/Math";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
function EarthAr() {
  function isMyScriptLoaded(url) {
    var scripts = document.getElementsByTagName("script");
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }
  useEffect(() => {
    const globe = document.querySelector("#button");
    if (globe)
      globe.childNodes.forEach((element) => {
        element.addEventListener("mouseleave", () => {
          document
            .getElementById("info-btn")
            .classList.replace("viewBtn", "disapearBtn");

          return;
        });
      });
    var hammertime = new window.Hammer(document, {
      cssProps: {
        touchAction: "none",
      },
    });

    hammertime.get("pinch").set({ enable: true, threshold: 0.5 });
    hammertime.get("pan").set({
      enable: true,
      direction: window.Hammer.DIRECTION_ALL,
    });
    hammertime.on("panup pandown panleft panright", function (event) {
      window
        .$("#button")
        .attr(
          "rotation",
          `${Math.round(
            window.$("#button").attr("rotation").x + event.deltaY / 50
          )} ${window.$("#button").attr("rotation").y + event.deltaX / 50} ${
            window.$("#button").attr("rotation").z
          }`
        );
    });

    hammertime.on("pinchstart", function (event) {
      //   event.preventDefault();
    });
    document.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
      },
      { passive: false }
    );
    let scale = 1;
    let last_scale = 1;
    hammertime.on("pinch pinchend", function (event) {
      this.scalefactor = 1;
      console.log("Pinch detected!");
      console.log(event);
      if (event.type == "pinch") {
        scale = Math.max(0.999, Math.min(last_scale * event.scale, 4));
      }
      if (event.type == "pinchend") {
        last_scale = scale;
      }
      if (scale >= 1 && scale <= 2.5)
        window.$("#button").attr("scale", `${scale} ${scale} ${scale}`);
      else if (scale <= 1) {
        window.$("#button").attr("scale", `${1} ${1} ${1}`);
      } else if (scale >= 2.5) {
        window.$("#button").attr("scale", `${2.5} ${2.5} ${2.5}`);
      }
    });

    if (
      !isMyScriptLoaded(
        "https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js"
      )
    ) {
      const script = document.createElement("script");
      script.src =
        "https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js";
      script.async = true;
      document.head.appendChild(script);
    }
    if (
      !isMyScriptLoaded(
        "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js"
      )
    ) {
      const script2 = document.createElement("script");
      script2.src =
        "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js";
      script2.async = true;
      document.head.appendChild(script2);
    }
    let start = false;

    var xDown = null;
    var yDown = null;
    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }

    var initialDistance = null;
    var isMinimizing = false;
  }, []);
  function zoomOut(e) {
    document
      .getElementById("info-btn")
      .classList.replace("viewBtn", "disapearBtn");
    e.stopPropagation();
    e.preventDefault();
    if (window.$("#button").attr("scale").x >= 0.5) {
      let earth = document.getElementById("button");
      let scaleFactor = 0.5;
      earth.setAttribute(
        "scale",
        `${earth.getAttribute("scale").x - scaleFactor} ${
          earth.getAttribute("scale").y - scaleFactor
        } ${earth.getAttribute("scale").z - scaleFactor}`
      );
      console.log(earth.getAttribute("scale"));
      console.log(earth.getAttribute("rotation"));
    }
  }
  function zoomIn(e) {
    document
      .getElementById("info-btn")
      .classList.replace("viewBtn", "disapearBtn");
    e.stopPropagation();
    e.preventDefault();
    if (window.$("#button").attr("scale").x <= 2) {
      let earth = document.getElementById("button");
      let scaleFactor = 0.5;
      earth.setAttribute(
        "scale",
        `${scaleFactor + earth.getAttribute("scale").x} ${
          scaleFactor + earth.getAttribute("scale").y
        } ${scaleFactor + earth.getAttribute("scale").z}`
      );

      console.log(earth.getAttribute("scale"));
      console.log(earth.getAttribute("rotation"));
    }
  }
  function goup() {
    document
      .getElementById("info-btn")
      .classList.replace("viewBtn", "disapearBtn");
    let earth = document.getElementById("button");
    let scaleFactor = 0.5;
    earth.setAttribute(
      "position",
      `${earth.getAttribute("position").x} ${
        scaleFactor + earth.getAttribute("position").y
      } ${earth.getAttribute("position").z}`
    );
  }

  function godown() {
    document
      .getElementById("info-btn")
      .classList.replace("viewBtn", "disapearBtn");
    let earth = document.getElementById("button");
    let scaleFactor = 0.5;
    earth.setAttribute(
      "position",
      `${earth.getAttribute("position").x} ${
        earth.getAttribute("position").y - scaleFactor
      } ${earth.getAttribute("position").z}`
    );
  }
  function Reset() {
    window
      .$("#button")
      .attr(
        "animation__2",
        `property: rotation; to: 0 0 0; loop: false; dur: 2000; easing: easeInOutSine;`
      );
    window
      .$("#button")
      .attr(
        "animation__3",
        `property: position; to: 0.75 5 -30; loop: false; dur: 2000; easing: easeInOutSine;`
      );
    window
      .$("#button")
      .attr(
        "animation__4",
        `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
      );
    window.$("#button").on("animationcomplete", function (event) {
      if (window.$("#button").attr("animation__2")) {
        window.$("#button").removeAttr("animation__2");
      }
      if (window.$("#button").attr("animation__3")) {
        window.$("#button").removeAttr("animation__3");
      }
      if (window.$("#button").attr("animation__4")) {
        window.$("#button").removeAttr("animation__4");
      }
      if (window.$("#button").attr("animation_tolocation")) {
        window.$("#button").removeAttr("animation_tolocation");
      }
      if (window.$("#button").attr("animation_torotation")) {
        window.$("#button").removeAttr("animation_torotation");
      }
    });
    // document.getElementById("button").setAttribute("scale", `1 1 1`);
    // document.getElementById("button").setAttribute("rotation", `0 0 0`);
    // document.getElementById("button").setAttribute("position", `0.75 5 -30`);
  }
  function handleClickEvent(e) {
    console.log("Clicked object:", e.detail.intersection.object.el);
  }
  const items = [
    {
      name: "Albania",
    },
    {
      name: "Croatia",
    },
    {
      name: "Cyprus",
    },
    {
      name: "Czech Republic",
    },
    {
      name: "Egypt",
    },
    {
      name: "Germany",
    },
    {
      name: "Ghana",
    },
    {
      name: "Greece",
    },
    {
      name: "Australia",
    },
    {
      name: "Iceland",
    },
    {
      name: "Ireland",
    },
    {
      name: "Italy",
    },
    { name: "Kenya" },
    {
      name: "Luxembourg",
    },
    {
      name: "Malta",
    },
    {
      name: "Netherlands",
    },
    {
      name: "New Zealand",
    },
    {
      name: "Oman",
    },
    {
      name: "Peru",
    },
    {
      name: "Qatar",
    },
    {
      name: "Romania",
    },
    {
      name: "Serbia",
    },
    {
      name: "Spain",
    },
    {
      name: "Tunisia",
    },
    {
      name: "Turkey",
    },
    {
      name: "UK",
    },
    {
      name: "United States of America",
    },
    {
      name: "Lesotho",
    },
    {
      name: "Mozambique",
    },

    {
      name: "Tanzania",
    },
    {
      name: "Democratic Republic of Congo",
    },
  ];

  const handleOnSelect = (item) => {
    // the item selected
    if (item.name == "Australia") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 354 240 -2; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Spain") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 58 9.4 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Albania") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 54 -8.78 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Croatia") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 60 -5.2 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Cyprus") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 50 -18.8 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Czech Republic") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 61 -4.2 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Egypt") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 41 -15.67 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Germany") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 67 -1.27 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Ghana") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to: 26 15.07 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Greece") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  52 -10.56 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Ireland") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  74 8.72 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Iceland") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  85 7.68 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Italy") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  57 -3.02 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Kenya") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  16 -25.24 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Serbia") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  58 -10.24 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Romania") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  59 -13.24 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Luxembourg") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  64 0.88 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Malta") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  51 -3.34 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "New Zealand") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  3 -166.5 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Netherlands") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  67 2.14 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Oman") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  43 -45.5 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Peru") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  315 -292 387; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Qatar") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  47 -38.2 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Tunisia") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  40 1.66 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Turkey") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  55 -17.64 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "UK") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  72 4.88 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "United States of America") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  249 120 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Mozambique") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  -1 -20.56 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Lesotho") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  -11 -10.34 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Democratic Republic of Congo") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  10 -10 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    } else if (item.name == "Tanzania") {
      window
        .$("#button")
        .attr(
          "animation__to",
          `property: rotation; to:  9 -20.54 0; loop: false; dur: 2000; easing: easeInOutSine;`
        );
      window
        .$("#button")
        .attr(
          "animation__scale",
          `property: scale; to: 1 1 1; loop: false; dur: 2000; easing: easeInOutSine;`
        );
    }
    window.$("#button").on("animationcomplete", function (event) {
      if (window.$("#button").attr("animation__to")) {
        window.$("#button").removeAttr("animation__to");
      }
      if (window.$("#button").attr("animation__scale")) {
        window.$("#button").removeAttr("animation__scale");
      }
    });
  };
  return (
    <>
      <div className={styles["modal"]} id='maintouch'>
        <div className={styles["header"]}>
          <div className={styles["header-container"]}>
            {/* <button type='button' className='btn btn-dark m-1' onClick={zoomIn}>
            +
          </button>
          <button type='button' class='btn btn-dark m-1' onClick={zoomOut}>
            -
          </button>
          <button type='button' class='btn btn-dark m-1' onClick={godown}>
            &darr;
          </button>
          <button type='button' class='btn btn-dark m-1' onClick={goup}>
            &uarr;
          </button>
           */}
            <img
              src='../../assets/poweringLogo.png'
              className={styles["logo"]}
            ></img>
            <div className={styles["searchDiv"]}>
              <ReactSearchAutocomplete
                items={items}
                onSelect={handleOnSelect}
              />
            </div>
          </div>
          <img
            src='/earth/resetIcon.svg'
            onClick={Reset}
            className={styles["reset-img"]}
          ></img>
        </div>
        {/*<button type='button' class='btn btn-dark m-1' onClick={goleft}>
          left
        </button>
        <button type='button' class='btn btn-dark m-1' onClick={goright}>
          right
        </button> */}
        <div className={styles["footer-container"]}>
          <button
            type='button'
            class=' disapearBtn'
            // data-toggle='modal'
            // data-target='#info'
            id='info-btn'
            // onClick={() => {
            //   window.opened = true;
            // }}
          >
            <img
              src='/earth/flags/Germany.png'
              className='pr-5'
              id='info-flag'
            ></img>
            Germany
          </button>
        </div>
        <div
          class='modal'
          id='info'
          tabindex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          data-backdrop='static'
          data-keyboard='false'
        >
          <div class='modal-dialog'>
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title' id='exampleModalLabel'>
                  Info
                </h5>
                <button
                  type='button'
                  class='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div class='modal-body'>
                <div className={styles["survey-body"]} id='modal-body'>
                  {" "}
                  Germany, officially the Federal Republic of Germany, is a
                  country in Central Europe. It is the second-most populous
                  country in Europe after Russia, and the most populous member
                  state of the European Union. Germany is situated between the
                  Baltic and North seas to the north, and the Alps to the south.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-scene
        vr-mode-ui='enabled: false'
        arjs='sourceType: webcam;videoTexture: true; debugUIEnabled: true;'
        foo
        cursor='rayOrigin: mouse'
      >
        <a-assets>
          {" "}
          <img id='myImage' src='/earth/_vois.png' />
        </a-assets>
        <a-image
          src='#myImage'
          position='0.75 4 -22'
          scale='3 2 2'
          id='logo'
          visible={false}
          // animation='property: scale; to: 15 15 15 dur: 2000; loop: true;'
        ></a-image>

        <a-entity position='0x 0y 0z'>
          <a-entity
            rotatehandler
            id='button'
            position='0.75 5 -30'
            scale='1 1 1'
            rotation='0 0 0'
            gltf-model='url(/earth/earth.glb)'
            animation-mixer='clip:*;loop: infinity;'
          >
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='40'
              id='egypt'
              class='intersectable'
              position='2.1 3.2 11'
              rotation='0 0 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='10'
              width='10'
              id='cyprus'
              class='intersectable'
              cursor-listener
              position='2.5 4.2 10'
              rotation='-15 0 -15'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 0'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='7.5'
              id='croatia'
              class='intersectable'
              cursor-listener
              position='0.2 6 9.4'
              rotation='-25 0 45'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='4.5'
              id='albania'
              class='intersectable'
              cursor-listener
              position='0.7 5.3 9.85'
              rotation='-25 0 25'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='6.5'
              id='italy'
              class='intersectable'
              cursor-listener
              position='-0.2 5.8 9.6'
              rotation='-25 0 35'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='20'
              width='10'
              id='berlin'
              class='intersectable'
              cursor-listener
              position='-0.4 7.15 8.53'
              rotation='-40 0 0'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='10'
              width='7.5'
              id='czech'
              class='intersectable'
              cursor-listener
              position='-0 6.75 9.2'
              rotation='-40 0 0'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='20'
              id='ghana'
              class='intersectable'
              position='-3.5 0. 10.62'
              rotation='0 0 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='7.5'
              width='9.5'
              id='greece'
              class='intersectable'
              cursor-listener
              position='1.1 4.8 10'
              rotation='-25 0 25'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='60'
              width='90'
              id='australia'
              class='intersectable'
              position='8.5 -4 -5.5'
              rotation='25 125 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 0'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='20'
              id='iceland'
              class='intersectable'
              cursor-listener
              position='-2.2 9.2 6'
              rotation='-60 -10 0'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='10'
              id='ireland'
              class='intersectable'
              cursor-listener
              position='-2.3 8 7.5'
              rotation='-50 0 0'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='5'
              width='5'
              id='luxembourg'
              class='intersectable'
              cursor-listener
              position='-0.9 6.75 8.83'
              rotation='-40 0 0'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='5'
              width='5'
              id='malta'
              class='intersectable'
              cursor-listener
              position='-0.2 4.58 10.2'
              rotation='-25 0 25'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='10'
              width='10'
              id='netherlands'
              class='intersectable'
              cursor-listener
              position='-1.1 7.20 8.50'
              rotation='-40 0 25'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='40'
              width='20'
              id='newzealand'
              class='intersectable'
              position='3 -5.5 -9.1'
              rotation='35 165 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 0'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='30'
              id='oman'
              class='intersectable'
              position='6.5 1.6 8.95'
              rotation='-15 35 45'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='40'
              width='15'
              id='peru'
              class='intersectable'
              position='-10.9 -1 -1.5'
              rotation='0 -95 25'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>

            <a-plane
              material='opacity: 0.0; transparent: true'
              height='10'
              width='15'
              id='spain'
              class='intersectable'
              cursor-listener
              position='-2.4 5.75 9.63'
              rotation='-35 0 -45'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 90 90'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='10'
              width='10'
              id='qatar'
              class='intersectable'
              position='5.7 2.75 9.5'
              rotation='-15 35 45'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='10'
              id='romania'
              class='intersectable'
              cursor-listener
              position='1.5 6 9.4'
              rotation='-25 0 45'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='12.5'
              width='7.5'
              id='serbia'
              class='intersectable'
              cursor-listener
              position='0.95 5.8 9.5'
              rotation='-25 0 35'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='10'
              id='tunisia'
              class='intersectable'
              position='-1.1 4.5 10.1'
              rotation='-25 -15 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='20'
              id='turkey'
              class='intersectable'
              cursor-listener
              position='2.3 5.15 9.7'
              rotation='-35 0 -15'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 0'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='10'
              id='uk'
              class='intersectable'
              cursor-listener
              position='-1.5 8 7.7'
              rotation='-50 0 -15'
              scale='0.07 0.07 0.07'
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='35 0 -45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='60'
              width='80'
              id='usa'
              class='intersectable'
              position='-10.2 7 -2.5'
              rotation='-45 -95 25'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='15'
              id='lesotho'
              class='intersectable'
              position='1 -6.8 8.87'
              rotation='35 0 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='24'
              id='mozambique'
              class='intersectable'
              position='2.7 -5.2 9.27'
              rotation='27 15 -25'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            {/* <a-plane
              material='opacity: 0.0; transparent: true'
              height='15'
              width='25'
              id='southafrica'
              class='intersectable'
              position='0 -7.8 7.7'
              rotation='35 0 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)' scale="2 2 2"
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane> */}
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='24'
              id='kenya'
              class='intersectable'
              position='4 -2 10.1'
              rotation='25 15 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='24'
              id='tanzania'
              class='intersectable'
              position='2.7 -3.5 10.12'
              rotation='25 15 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
            <a-plane
              material='opacity: 0.0; transparent: true'
              height='30'
              width='24'
              id='congo'
              class='intersectable'
              position='1 -3 10.62'
              rotation='15 5 0'
              scale='0.07 0.07 0.07'
              cursor-listener
            >
              <a-entity
                gltf-model='url(/earth/pinnn2.glb)'
                scale='0.07 0.07 0.07'
                rotation='0 0 45'
                position='0 0 0'
              ></a-entity>
            </a-plane>
          </a-entity>
        </a-entity>

        {/* <a-plane
          position='0 1.6 -1'
          height='2'
          width='1.5'
          color='red'
          class='cursor-listener'
          cursor-listener
          id='element'
        ></a-plane> */}

        <a-entity
          camera
          look-controls
          mouse-cursor
          raycaster='objects: .intersectable; showLine: true; far: 100'
          line='color: red; width: 2'
        ></a-entity>
      </a-scene>
    </>
  );
}

export default EarthAr;
