import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import domtoimage from "dom-to-image";
import parse, { domToReact } from "html-react-parser";
import "./photoBooth.css";
import { Link } from "react-router-dom";
import axios from "axios";
import auth, { getCurrentUser } from "../../../services/authService";
import { WorkplaceIcon, WorkplaceShareButton } from "react-share";
const PhotoBooth = (props) => {
  const [imageurl, setImageUrl] = useState();
  const history = useNavigate();
  const [loadBytes, setLoadBytes] = React.useState(true);
  let videoStream;
  let file;
  const location = useLocation();
  function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }
  useEffect(() => {
    let width = 300,
      height = 0,
      filter = "none",
      streaming = false;

    const video = document.getElementById("video");
    const photoButton = document.getElementById("photo-button");
    const photoFilter = document.getElementById("photo-filter");
    const clearButton = document.getElementById("clear-button");
    const canvas = document.getElementById("canvas");
    const photos = document.getElementById("photos");

    // getting the stream from the webcam and import it into the video element
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then((stream) => {
        // link to the video source
        videoStream = stream;
        video.srcObject = stream;

        video.play();
      })
      .catch((err) => {});

    // Play video when ready
    video.addEventListener(
      "canplay",
      function (e) {
        if (!streaming) {
          // Set video / canvas height
          height = video.videoHeight / (video.videoWidth / width);

          video.setAttribute("width", width);
          video.setAttribute("height", height);
          canvas.setAttribute("width", width);
          canvas.setAttribute("height", height);

          streaming = true;
        }
      },
      false
    );

    // Photo button event
    photoButton.addEventListener(
      "click",
      function (e) {
        takePicture();

        e.preventDefault();
      },
      false
    );

    // Filter event
    photoFilter.addEventListener("change", function (e) {
      // Set filter to chosen option
      filter = e.target.value;
      // Set filter to video
      video.style.filter = filter;

      e.preventDefault();
    });

    // Clear event
    clearButton.addEventListener("click", function (e) {
      // Clear photos
      photos.innerHTML = "";
      // Change filter back to none
      filter = "none";
      // Set video filter
      video.style.filter = filter;
      // Reset select list
      photoFilter.selectedIndex = 0;
    });

    // Take picture from canvas
    function takePicture() {
      setLoadBytes(false);
      // Create canvas
      const context = canvas.getContext("2d");
      if (width && height) {
        // set canvas props
        canvas.width = width;
        canvas.height = height;

        // Draw an image of the video on the canvas
        context.drawImage(video, 0, 0, width, height);

        // Create image from the canvas
        const imgUrl = canvas.toDataURL("image/png");
        const frameImage = document.createElement("Div");
        axios
          .get(
            `${
              process.env.REACT_APP_SERVER_URL
            }mosaicWall/${localStorage.getItem(
              "selectedEventName"
            )}/Generalassets/PhotoboothFrame-img`
          )
          .then((res) => {
            frameImage.style.backgroundImage = `url('${res.data}')`;
            domtoimage
              .toBlob(frameImage)
              .then(function (blob) {
                btn.href = URL.createObjectURL(blob);

                btn.download = `Envirtual${Date.now()}.png`;
                setLoadBytes(true);
              })
              .catch((err) => console.log(err));
          });
        frameImage.style.backgroundRepeat = "no-repeat";
        frameImage.style.width = "19.5rem";
        frameImage.style.height = "18rem";
        frameImage.style.backgroundSize = "cover";
        getCurrentUser().then((r) => {
          axios.post(`${process.env.REACT_APP_SERVER_URL}mosaicWall`, {
            imageBytes: imgUrl,
            userName: r.preferred_username.split(".")[0],

            eventName: localStorage.getItem("selectedEventName"),
          });
        });

        // create frame container DOM
        const frameContainer = document.createElement("DIV");
        frameContainer.setAttribute(
          "class",
          "frame-Container align-items-center col-xl-4 col-lg-6 col-md-12 col-s-12"
        );

        // create h2 element DOM
        const photoBoothName = document.createElement("h3");
        photoBoothName.style.color = "white";
        photoBoothName.style.position = "relative";
        photoBoothName.style.top = "-40px";
        photoBoothName.style.left = "31px";
        photoBoothName.innerHTML = auth
          .getCurrentUser()
          .preferred_username.split(".")[0];

        // Create img element DOM
        const img = document.createElement("img");

        // Set img src
        img.setAttribute("src", imgUrl);
        img.style.width = "17.75rem";
        img.style.padding = "1rem 1rem";
        if (localStorage.getItem("selectedEventName") == "emm") {
          img.style.paddingTop = "5rem";
        }
        // Set image filter
        img.style.filter = filter;
        frameImage.appendChild(img);
        frameImage.appendChild(photoBoothName);

        const btnDiv = document.createElement("DIV");
        btnDiv.setAttribute("class", "mt-2 ");
        const btn = document.createElement("a");
        btn.setAttribute("class", "btn  btn-danger");

        btn.innerHTML = "Download";

        photos.appendChild(frameContainer);
        // Add image to photos
        frameContainer.appendChild(frameImage);

        frameContainer.appendChild(btnDiv);

        btnDiv.appendChild(btn);
      }
    }
    return function () {
      if (videoStream != undefined)
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
    };
  }, []);

  const handleMosaicButton = () => {
    history("/mosaicWall");
  };
  return (
    <>
      <div className='container-fluid photobooth-container'>
        <Link
          type='button'
          className='btn btn-light mb-2 '
          style={{ position: "fixed", right: 15, top: 5 }}
          to={
            localStorage.selectedEventName == "emm"
              ? "emm/beanbags"
              : "/breakroom"
          }
        >
          {localStorage.selectedEventName == "emm"
            ? "Back to waiting area"
            : "Back to Fun Area"}
        </Link>
        <img
          className='photobooth-img'
          src='../assets/photobooth-background.jpg'
          alt=''
        />
        <div className='row col-xs-12 d-flex  justify-content-center'>
          <h1 className=' text-light  mt-5'>Photobooth</h1>
        </div>
        <div className=' row col-xs-12 d-flex  justify-content-center video-top-container'>
          <video id='video'>Stream not available..</video>
        </div>
        <div className='controls-container row  d-flex justify-content-center '>
          <button
            id='photo-button'
            disabled={loadBytes == true ? false : true}
            className='btn  btn-danger mr-1'
          >
            Take photo
          </button>

          <select name='' className=' btn btn-danger mr-1' id='photo-filter'>
            <option value='none'>Normal</option>
            <option value='grayscale(100%)'>Grayscale</option>
            <option value='sepia(100%)'>Sepia</option>
            <option value='invert(100%)'>Invert</option>
            <option value='hue-rotate(90deg)'>Hue</option>
            <option value='blur(10px)'>Blur</option>
            <option value='contrast(200%)'>Contrast</option>
          </select>

          <button id='clear-button' className='btn btn-danger'>
            Clear
          </button>
          <Link
            className='btn btn-danger ml-1'
            to={{
              pathname: "/mosaicWall",
              state: { prevPath: location.pathname },
            }}
          >
            Show moasic wall
          </Link>
        </div>
        <canvas id='canvas'></canvas>
      </div>

      <div className='video-bottom-container'>
        <div id='photos' className='row'></div>
      </div>
    </>
  );
};

export default PhotoBooth;
