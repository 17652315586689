import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";
function AddAdmins() {
  const location = useLocation();
  console.log(location.state);
  const [project, setProject] = React.useState({
    Name: location.state.projectName,
    path: location.state.projectPath,
    disableSendingMails: false,
  });
  const [disableSendingMails, setDisableSendingMails] = React.useState(false);
  const [groupName, setGroupName] = React.useState("");
  const [emails, setEmails] = React.useState([]);

  const projectName = useRef("");
  const history = useNavigate();
  let stepper;
  const textboxDiv = useRef();
  useEffect(() => {
    // stepper = new Stepper(document.querySelector("#stepper1"), {
    //   linear: false,
    //   animation: true,
    // });
  }, []);

  const onSubmit = () => {
    let data = {
      project: project,
      groupName: groupName,
      emails: emails,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/AddAdmins`, data)
      .then((data) => history("/user/project"));
  };
  let counter;
  const addTextBox = (value) => {
    let elem = document.querySelector("#textBoxes");

    if (elem.firstChild !== null) {
      counter = parseInt(elem.firstChild.id) + 1;
    } else {
      counter = 0;
    }
    var inp = document.createElement("input");
    inp.type = "email";
    inp.className = "form-control col-md-5 col-xs-12 mt-2 mb-2 mr-2 ml-4";
    inp.id = counter;
    var remove = document.createElement("label");
    remove.innerHTML = "x";
    remove.className = counter;
    remove.onclick = removeValue.bind(counter);

    if (typeof value == "string") {
      remove.setAttribute("data-email", value);
      inp.value = value;
      let e = emails;
      e.push(value);
      setEmails(e);

      inp.onload = handleChange.bind(this);
    }
    elem.prepend(remove);
    elem.prepend(inp);

    inp.onchange = handleChange.bind(this);
  };

  const handleChange = (e) => {
    debugger;
    var change = emails;

    if (parseInt(e.target.id) <= change.length) {
      change[parseInt(e.target.id)] = e.target.value.toLowerCase();
    } else {
      change.push(e.target.value.toLowerCase());
    }
    setEmails(change);
  };

  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        if (
          !element[0].startsWith("+") &&
          !element[0].startsWith("=") &&
          !element[0].startsWith("-")
        ) {
          addTextBox(element[0]);
        }
      }
    };
    reader.readAsBinaryString(f);
  };
  function removeValue(e) {
    let email = e.target.getAttribute("data-email");
    let elem = document.getElementById(e.target.className);
    elem.parentElement.removeChild(e.target);
    elem.parentElement.removeChild(elem);
    let i = emails.indexOf(email);
    console.log(e.target.className);
    if (i > -1) {
      let change = emails.splice(i, 1);
      setEmails(change);
    }
  }
  return (
    <div className='maindiv row'>
      <img
        src='/assets/background.png'
        className='mentor-card-screen-background'
        alt=''
      />

      <Link
        type='button'
        className='btn btn-light mb-2 '
        style={{ position: "fixed", right: 15, top: 5 }}
        to='/projects'
      >
        Back to Projects
      </Link>
      <div className='col-md-3'></div>
      <div className='col-md-6 col-xs-6 mt-5' style={{ position: "relative" }}>
        <div id='test-l-2' className='content'>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4>Add Admin Emails</h4>
            <button
              className='btn btn-light text-center'
              style={{ borderRadius: "50px" }}
              onClick={addTextBox}
            >
              +
            </button>
            <input
              type='file'
              className='btn btn-light text-center mb-2'
              onChange={handleUpload}
            ></input>
          </div>
          <div className='row' id='textBoxes'></div>
          <div className='row'>
            <div class='checkbox  col-md-8 col-xs-12'>
              <label>
                <input
                  type='checkbox'
                  data-toggle='toggle'
                  className='mr-3'
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setDisableSendingMails(e.target.checked);
                  }}
                />
                Disable Sending Mails For Vodafoners
              </label>
            </div>

            <span className='col-md-4 col-xs-12'>
              <button
                type='submit'
                className='btn btn-light mr-3 ml-3 float-right '
                onClick={onSubmit}
              >
                Submit
              </button>
            </span>
          </div>
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}

export default AddAdmins;
