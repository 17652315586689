import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AscentionDemoRoomsManagement() {
  const [data, setdata] = useState([]);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [roomOwner, setroomOwner] = React.useState("");
  const [roomLink, setroomLink] = React.useState(".");
  const [roomID, setroomID] = React.useState();
  useEffect(() => {
    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}ascentionrooms`)
      .then((res) => {
        setdata(res.data);
      });
  }, []);
  const resetroomdetails = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}ascentionrooms/addRoomOwner`, {
        id: roomID,
        roomDetails: {
          ownerEmail: roomOwner,
          roomLink: roomLink,
        },
      })
      .then((res) => {
        window.location.reload(true);
      });
  };
  return (
    <div>
      <div
        class='modal fade'
        id={"editMainDetails"}
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-lg' role='document'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title' id='exampleModalLabel'>
                Edit Demo room Data
              </h5>
              <button
                type='button'
                class='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div class='modal-body row'>
              <div className='col-md-12'>
                <p>Room Owner Email:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setroomOwner(el.target.value);
                  }}
                  value={roomOwner}
                ></input>
              </div>
              <div className='col-md-12'>
                <p>Room Link:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setroomLink(el.target.value);
                  }}
                  value={roomLink}
                ></input>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                class='btn btn-danger'
                data-dismiss='modal'
                onClick={resetroomdetails}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        src='/assets/_VOIS_Hackathon_keyart2.jpg'
        className='breakroom-listing-screen-background'
        alt=''
      />
      <div className='breakRoom-container-fluid'>
        <div className=' pl-5 nav-button-padding'>
          <img
            src='/assets/background.png'
            className='mentor-card-screen-background'
            alt=''
          />
          <div
            className='row'
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5rem",
            }}
          >
            <h2 style={{ color: "white", position: "absolute" }}>
              Demo Links Management
            </h2>
            <Link
              type='button'
              className='btn btn-light mb-2 '
              style={{ position: "fixed", right: 15, top: 5 }}
              to='/projects'
            >
              Back to Projects
            </Link>
          </div>
          {loadBytes ? (
            <div className='row mt-5'>
              <div className='col-md-11 ml-5'>
                <table class='table table-dark'>
                  <thead>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>Link</th>
                      <th scope='col'>Owner Email</th>
                      <th scope='col'>Is Disabled</th>
                      <th scope='col'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((r) => (
                      <tr>
                        <td>{r.roomName}</td>
                        <td>{r.roomLink}</td>
                        <td>{r.ownerEmail}</td>
                        <td>{r.isDisabled}</td>
                        <td>
                          <button
                            style={{
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                            }}
                            data-toggle='modal'
                            data-target={"#editMainDetails"}
                            onClick={() => {
                              setroomLink(r.roomLink);
                              setroomOwner(r.ownerEmail);
                              setroomID(r._id);
                            }}
                          >
                            <div
                              className='fa fa-pencil'
                              style={{ cursor: "pointer", color: "white" }}
                            ></div>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className='text-center'>
              <div className='spinner-grow text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-secondary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-success' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-danger' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-warning' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-info' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-light' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div className='spinner-grow text-dark' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}

export default AscentionDemoRoomsManagement;
