import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";

import TextContainer from "../TextContainer/TextContainer";
import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import auth, { getCurrentUser } from "../../../../services/authService";
import "./liveChat.css";
// import { getCurrentUser } from "../../../services/authService";
const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;

let socket;
let socketID;
const LiveChat = ({ nickname, chatroom, event }) => {
  const [name, setName] = useState(nickname);
  // const [userName, setUserName] = useState(
  //   getCurrentUser().email !== null
  //     ? getCurrentUser().email
  //     : getCurrentUser().preferred_username
  // );
  const [userName, setUserName] = useState();
  const [room, setRoom] = useState(chatroom);
  const [eventName, setEventName] = useState(event);
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getCurrentUser().then((r) => {
      setUserName(r.preferred_username);
    });
    socket = io(ENDPOINT, {
      path: "/socket/",
    });

    socket.on("connect", function () {
      // console.log("Connected! ID: " + socket.id);

      socketID = socket.id;
    });

    setRoom(room);
    setName(name);
    setEventName(event);
    // setUserName(
    //   getCurrentUser().email !== null
    //     ? getCurrentUser().email
    //     : getCurrentUser().preferred_username
    // );

    socket.emit("join", { name, userName, room, eventName }, (error) => {
      // console.log("join error", error);
      localStorage.setItem("chatSocket", socketID);
      if (error) {
        alert(error);
      }
    });
    return function () {
      socket.close();
    };
  }, [ENDPOINT, chatroom, chatroom]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages(message);
    });
    socket.on("singlemessage", (message) => {
      // console.log("sending", message);

      setMessages(message);
    });
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();

    socketID = localStorage.getItem("chatSocket");
    if (message) {
      // console.log();
      socket.emit("sendMessage", { message, socketID }, () => setMessage(""));
    }
  };

  return (
    <div className='container-fluid general-chat'>
      <div className='row '>
        <div className='col-sm-9 chat-container p-0'>
          <InfoBar room={room} />

          <Messages messages={messages} name={name} />
          <Input
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
          />
        </div>
        <div className='col-sm-3 '>
          <TextContainer users={users} />
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
};

export default LiveChat;
