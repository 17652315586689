import React from "react";
import { Link } from "react-router-dom";
import "./breakRoom.css";

const backGroundImage = "/assets/playCard.jpg";

const BreakRoom = () => {
  return (
    <div>
      <img
        src='/assets/_VOIS_Hackathon_keyart2.jpg'
        className='breakroom-listing-screen-background'
        alt=''
      />
      <div className='breakRoom-container-fluid'>
        <div className=' pl-5 nav-button-padding'>
          <img
            src='/assets/background.png'
            className='mentor-card-screen-background'
            alt=''
          />

          <Link
            type='button'
            className='btn btn-light mb-2 '
            style={{ position: "fixed", right: 15, top: 5 }}
            to={`./${localStorage.getItem("selectedEventName")}/hall2`}
          >
            Back to hall
          </Link>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12 text-center'>
            <h2 className='mentors-header-page-title'>
              Welcome To BreakRoom Area
            </h2>
            <h6 className='mentors-header-page-title'>
              Have fun and get your image with vodafone frame or communicate
              with others
            </h6>
          </div>
        </div>

        <div className='row cards-flip-container'>
          <div className='col-md-6 col-lg-4 col-xs-12 car-margin d-flex justify-content-center pr-4'>
            <div className='playground-flip'>
              <div
                className='playground-front'
                style={{
                  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
                  backgroundImage: `url(${backGroundImage})`,
                  backgroundSize: "102% 107%",
                }}
              >
                <h1 className='text-shadow'>PhotoBooth</h1>
              </div>
              <div className='playground-back text-center'>
                <h2>
                  Take a photo with our frame to get the spirit of connection
                </h2>
                <div className='mentor-card-button-container '>
                  <Link
                    rel='noreferrer'
                    to='/photoBooth'
                    className='  playground-card-button'
                  >
                    Take Photo
                  </Link>
                  <Link
                    rel='noreferrer'
                    to='/mosaicWall'
                    className='  playground-card-button'
                  >
                    Mosaic Wall
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-4 col-xs-12 car-margin d-flex justify-content-center'>
            <div className='playground-flip'>
              <div
                className='playground-front'
                style={{
                  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
                  backgroundImage: `url(${backGroundImage})`,
                  backgroundSize: "102% 107%",
                }}
              >
                <h1 className='text-shadow'>Chat Rooms</h1>
              </div>
              <div className='playground-back text-center'>
                <h2>
                  Join different chat rooms to gain experience with your field
                </h2>
                <div className='mentor-card-button-container text-center'>
                  <Link
                    rel='noreferrer'
                    to='/chatLogin?joinroom=true'
                    className='  playground-card-button'
                  >
                    Join room
                  </Link>
                  <Link
                    rel='noreferrer'
                    to='/chatLogin?joinroom=false'
                    className='  playground-card-button mt-2'
                  >
                    Create room
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-lg-4 col-xs-12 car-margin d-flex justify-content-center'>
            <div className='playground-flip'>
              <div
                className='playground-front'
                style={{
                  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
                  backgroundImage: `url(${backGroundImage})`,
                  backgroundSize: "102% 107%",
                }}
              >
                <h1 className='text-shadow'>Games</h1>
              </div>
              <div className='playground-back text-center'>
                <h2>Have a break and try our games</h2>
                <div className='mentor-card-button-container text-center'>
                  <Link
                    rel='noreferrer'
                    to='/game/1'
                    className='  playground-card-button'
                  >
                    Soduko
                  </Link>
                  <Link
                    rel='noreferrer'
                    to='/game/2'
                    className='  playground-card-button mt-2'
                  >
                    Remember Cards
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
};

export default BreakRoom;
