import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import "./chatLogin.css";
import Select from "react-select";
import Axios from "axios";
import LiveChat from "../liveChat/liveChat";
import auth, { getCurrentUser } from "../../../../services/authService";
// import { getCurrentUser } from "../../../services/authService";
export default function SignIn({ location }) {
  const [rooms, setRooms] = useState("");
  const [room, setRoom] = useState("");
  const [joinOldRoom, setjoinOldRoom] = useState("");
  const [btnClicked, setbtnClicked] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [name, setName] = useState();
  useEffect(() => {
    const { joinroom } = queryString.parse(location.search);
    setjoinOldRoom(joinroom);
    Axios.get(
      `${process.env.REACT_APP_SERVER_URL}chat/${localStorage.getItem(
        "selectedEventName"
      )}`
    ).then((res) => {
      setRooms(
        res.data.map((el) => {
          // console.log(el.roomCount > 2);
          return {
            value: el.roomName,
            label: el.roomName,
          };
        })
      );
    });
    getCurrentUser().then((r) => setName(r.preferred_username.split(".")[0]));
  }, []);
  function handleRoomNameChange(option) {
    setRoom(option.value);
    setbtnDisabled(false);
  }
  function handleRoomNameintB(e) {
    setRoom(e.target.value);
    setbtnDisabled(false);
  }

  return btnClicked ? (
    <LiveChat
      nickname={name}
      chatroom={room}
      event={localStorage.getItem("selectedEventName")}
    />
  ) : (
    <div className='joinOuterContainer'>
      <div className='joinInnerContainer'>
        <h1 className='heading'>Envirtual Chat </h1>

        <div className='btn-width'>
          {joinOldRoom === "false" ? (
            <input
              placeholder='Room'
              className='joinInput mt-20 form-control'
              type='text'
              onChange={handleRoomNameintB}
            />
          ) : (
            <Select
              className='bounce-in-top ddl-SL-z-index mt-3 w-30 h-20'
              onChange={handleRoomNameChange}
              options={rooms}
            />
          )}{" "}
        </div>
        <div className='join-btns-container'>
          {" "}
          <button
            className='btn  btn-outline-light button mt-4 mb-4'
            disabled={btnDisabled}
            onClick={() => {
              setbtnClicked(true);
            }}
          >
            Know what's going on !!
          </button>
        </div>
        <div className='back-btns-container d-flex justify-content-between'>
          {" "}
          <NavLink to={`/breakroom`} className='btn  btn-outline-light button '>
            Back to Fun Area
          </NavLink>
          {/* <NavLink to={`/hall`} className='btn  btn-outline-light '>
            Create
          </NavLink> */}
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}
