import { jwtDecode } from "jwt-decode";

import http, { setJwt } from "./httpService";

const apiEndpoint = process.env.REACT_APP_SERVER_URL + "auth";
let tokenKey;
export const getCredentials = async () =>
  fetch("/appSettings.json")
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(console.log);

export const login = async (loginUser, recaptchaToken) => {
  const { data: jwt } = await http.post(
    apiEndpoint,
    {
      email: loginUser.email,
      password: loginUser.password,
    },
    { headers: { RecaptchaHeader: recaptchaToken } }
  );
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  localStorage.setItem(tokenKey, jwt);
};

export const loginWithJwt = async (jwt) => {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  localStorage.setItem(tokenKey, jwt);
};

export const getCurrentUser = async () => {
  try {
    let tokenCred = await getCredentials();
    tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
    const jwt = localStorage.getItem(tokenKey);
    console.log(jwt);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
    // console.log(error);
    // console.log(!user);
  }
};

export async function getJwt() {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  return localStorage.getItem(tokenKey);
}

export const logout = async () => {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  localStorage.removeItem(tokenKey);
};

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
