import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import auth, { getCurrentUser } from "../../services/authService";
import styles from "./WIVALiveChat.module.css";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVATextContainer from "./WIVATextContainer/WIVATextContainer";
import { useLocation } from "react-router-dom";
import WIVAInfoBar from "./WIVAInfoBar/WIVAInfoBar";
import WIVAMessages from "../WIVAMessages/WIVAMessages";
import WIVAInput from "./WIVAInput/WIVAInput";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
import { useNavigate } from "react-router-dom";
const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;

let socket;
let socketID;
function WIVALiveChat(props) {
  const location = useLocation();
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const [room, setRoom] = useState(location.state?.roomname);
  const [roomDescription, setRoomDescription] = useState(
    location.state?.roomdesc
  );

  const [eventName, setEventName] = useState("wiv");
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [messagesUsers, setMessagesUsers] = useState([]);

  useEffect(() => {
    const setupSocket = async () => {
      // Create and configure socket
      socket = io(ENDPOINT, {
        path: "/socket/",
        reconnection: true,
        reconnectionDelay: 5,
        reconnectionAttempts: 1000,
      });

      // Get user information
      const user = await getCurrentUser();
      setUserName(user.preferred_username);

      // Room, name, and username logic
      setRoom(location.state?.roomname);
      setRoomDescription(location.state?.roomdesc);
      setName(
        user.preferred_username.split(".")[0] +
          " " +
          user.preferred_username.split(".")[1].split("@")[0]
      );
      setEventName("wiv");

      // Join room (after username is likely fetched)
      const updatedUser = await getCurrentUser(); // Get updated info if needed
      socket.on("roomMainInfo", (retuned) => {
        setRoomDescription(retuned.description);
      });
      socket.emit(
        "join",
        {
          name:
            user.preferred_username.split(".")[0] +
            " " +
            user.preferred_username.split(".")[1].split("@")[0],
          userName: user.preferred_username,
          room,
          roomDescription,
          eventName: "wiv",
        },
        (error) => {
          // console.log("join error", error);
          localStorage.setItem("chatSocket", socketID);
          if (error) {
            alert(error);
          }
        }
      );
      // Error Handling (as you had it)
      socket.on("error", function (e) {
        console.log("reconnect", e);
      });

      socket.on("disconnect", () => {
        console.log("Client Disconnected from Socket!");
      });
    };

    setupSocket(); // Execute the setup

    // Cleanup - close socket
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    socket.on("singlemessage", (message) => {
      //  console.log("*** Client - singlemessage received ***", message);
      setMessages(message);
    });

    socket.on("message", (message) => {
      // console.log("*** Client - Message received ***", message);
      setMessages(message);
      let users = [];
      for (let i = 0; i < message.length; i++) {
        if (users.filter((r) => r == message[i].user).length == 0)
          users.push(message[i].user);
      }
      setMessagesUsers(users);
    });
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  });
  const [repliedtoMsg, setRepliedToMsg] = React.useState(null);
  const [repliedtoHTML, setRepliedtoHTML] = React.useState(``);
  const [repliedtoHTMLIndex, setRepliedtoHTMLIndex] = React.useState(0);
  const replyto = (data) => {
    console.log(data);
    setRepliedToMsg(data);
    setRepliedtoHTML(`<div
            class="wiva-reply-span"
            
          >
            <p
              class=
             "wiva-replymessageTextleft wiva-replycolorDark-name pl-10 "
              
            >
              ${data.user}
            </p>
            <p
              class=
                "wiva-replymessageTextleft wiva-replycolorDark  pl-10 "
             
            >
              ${data.message}
            </p>
          </div>`);
    setRepliedtoHTMLIndex(data.msgindex);
    document.getElementById("chat-textbox").focus();
  };
  const clearreply = () => {
    setRepliedToMsg(null);
    setRepliedtoHTMLIndex(0);
    setRepliedtoHTML(``);
  };
  const [selectedEmojiList, setSelectedEmojiList] = React.useState([]);
  const setMessageReaction = ({ _id, reactions }) => {
    console.log("*** Client sendMessage - Initiated ***");

    socket.emit(
      "sendEmojiOnMessage",
      { _id, reactions, userName, room },
      () => {
        debugger;
        let emojiPickers = document.getElementsByClassName("emojidiv");
        for (let i = 0; i < emojiPickers.length; i++) {
          emojiPickers[i].style.display = "none";
        }
      }
    );
  };
  const sendMessage = (event) => {
    event.preventDefault();
    console.log(message);

    socketID = localStorage.getItem("chatSocket");
    if (message) {
      console.log("*** Client sendMessage - Initiated ***");
      let newMessage = message;

      socket.emit(
        "sendMessage",
        { message, userName, repliedtoHTML, repliedtoHTMLIndex, room },
        () => {
          setMessage("");
          clearreply();
        }
      );
      // console.log("***messages:: ****", messages);
      // console.log("***message:: ****", message);
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { text: message, user: userName }, // Assuming 'userName' has the current user
      // ]);

      // console.log("***messages after update:: ****", messages);
    }
  };
  const history = useNavigate();

  const addSelectedEmojiList = (data) => {
    let x = selectedEmojiList;
    x.push(data);
    console.log(x);
    setSelectedEmojiList(x);
  };
  return (
    <div className={styles["container-fluid"] + " "}>
      <div className={styles["row"] + " row"}>
        <div className='col-md-12 p-0'>
          <WivaNavBar></WivaNavBar>
          <WIVASideNavBar></WIVASideNavBar>
          <div className={styles["row"] + " row"}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              className={styles["back-div"]}
              onClick={() => {
                history("/wiv/chatentry");
              }}
            >
              <path
                d='M15.3214 0H4.67861C2.08641 0 0 2.10748 0 4.67861V15.3214C0 17.9136 2.10748 20 4.67861 20H15.3214C17.9136 20 20 17.8925 20 15.3214V4.67861C20 2.10748 17.8925 0 15.3214 0ZM18.6723 15.3425C18.6723 17.1971 17.1549 18.7144 15.3003 18.7144H4.65754C2.80296 18.7144 1.28557 17.1971 1.28557 15.3425V4.69968C1.28557 2.8451 2.80296 1.32771 4.65754 1.32771H15.3003C17.1549 1.32771 18.6723 2.8451 18.6723 4.69968V15.3425Z'
                fill='#4A4D4E'
              />
              <path
                d='M12.6198 6.01562C12.4536 5.82483 12.2194 5.70858 11.9685 5.69238C11.7177 5.67618 11.4708 5.76135 11.282 5.9292L7.48676 9.28996C7.3858 9.38007 7.30494 9.49089 7.24956 9.61505C7.19417 9.73922 7.16553 9.87389 7.16553 10.0101C7.16553 10.1464 7.19417 10.281 7.24956 10.4052C7.30494 10.5294 7.3858 10.6402 7.48676 10.7303L11.282 14.091C11.455 14.2454 11.6776 14.3308 11.9082 14.3311C12.0429 14.3309 12.176 14.3017 12.2987 14.2454C12.4214 14.1891 12.5309 14.1071 12.6198 14.0046C12.7856 13.8135 12.8698 13.5636 12.8538 13.3098C12.8378 13.056 12.7229 12.8189 12.5344 12.6507L9.55515 10.0101L12.5344 7.36952C12.7229 7.20131 12.8378 6.96428 12.8538 6.71045C12.8698 6.45661 12.7856 6.20672 12.6198 6.01562Z'
                fill='#4A4D4E'
              />
            </svg>
            <div className='col-md-12  p-0'>
              <div className={styles["joinInnerContainer"]}>
                <div className={styles["first-div-row"] + " row p-0 m-0"}>
                  <div
                    className={styles["chat-room-div"] + " col-md-3 col-sm-12"}
                  >
                    <WIVATextContainer users={users} />
                  </div>
                  <div
                    className={styles["chat-room-div2"] + " col-md-9 col-sm-12"}
                  >
                    <WIVAInfoBar room={room} desc={roomDescription} />
                    <WIVAMessages
                      messages={messages}
                      name={name}
                      users={users}
                      onmessageReply={replyto}
                      setMessageReaction={setMessageReaction}
                    />
                    <WIVAInput
                      repliedtoMsg={repliedtoMsg}
                      message={message}
                      setMessage={setMessage}
                      sendMessage={sendMessage}
                      clearreply={clearreply}
                      messagesUsers={messagesUsers}
                      setSelectedEmojiList={addSelectedEmojiList}
                      selectedEmojiList={selectedEmojiList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WIVALiveChat;
