import axios from "axios";

import logger from "./logService";
import CryptoJS from "crypto-js";
import { getCredentials, getJwt } from "./authService";

axios.interceptors.request.use(async (request) => {
  request.headers.setAuthorization("Bearer " + localStorage.getItem("token"));
  if (
    request.url.includes("/api/eventassets") ||
    request.url.includes("/api/wivaconfig/uploadAssets") ||
    request.url.includes("/api/mosaicWall")
  )
    return request;
  else {
    request.data = {
      payload: CryptoJS.AES.encrypt(
        JSON.stringify(request.data),
        "secret key 123"
      ).toString(),
    };
    return request;
  }
});

axios.interceptors.response.use(
  (res) => {
    if (
      res.config.url.includes("/api/mosaicWall") ||
      res.config.url.includes("/api/eventassets") ||
      res.config.url.includes("/api/stats/setpage")
    )
      return Promise.resolve(res);
    let decrypted = CryptoJS.AES.decrypt(res.data, "secret key 123");

    let decryptedItem = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    res.data = decryptedItem;
    return Promise.resolve(res);
  },
  (error) => {
    const expectedErrors =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedErrors) {
      logger.log(error);
    }
    let decrypted = CryptoJS.AES.decrypt(error.response.data, "secret key 123");

    let decryptedItem = decrypted.toString(CryptoJS.enc.Utf8);
    console.log(decryptedItem);
    error.response.data = decryptedItem;
    if (decryptedItem == JSON.stringify("Concurrent User")) {
      document.getElementById("concurrentuserbtn").click();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export function setJwt() {
  return getCredentials().then((tokenCred) => {
    let tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
    if (localStorage.getItem(tokenKey) != undefined) {
      localStorage.setItem("token", localStorage.getItem(tokenKey));
      if (
        window.location.pathname != "/user/project" &&
        localStorage.getItem("token") != undefined
      )
        window.location.href = "/user/project";
    }
  });
}
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
