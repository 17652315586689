import React from "react";

import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import { setJwt } from "../../services/httpService";
// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
let globalobj;
let authProvider;
const getCredentials = async () =>
  fetch("/appSettings.json")
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(console.log);
const getAll = async () => {
  let tokenCred = await getCredentials();
  globalobj = tokenCred;
  return new MsalAuthProvider(
    {
      auth: {
        authority: globalobj.Authority,
        clientId: globalobj.Client_ID,
        postLogoutRedirectUri: `${globalobj.ENVIRTUAL_BASE_URL}`,
        redirectUri: `${globalobj.ENVIRTUAL_BASE_URL}/entrance`,
        validateAuthority: true,

        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: false,
      },
      // Enable logging of MSAL events for easier troubleshooting.
      // This should be disabled in production builds.
      system: {
        logger: new Logger((logLevel, message, containsPii) => {}, {
          level: LogLevel.Verbose,
          piiLoggingEnabled: false,
        }),
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    },
    {
      scopes: ["openid"],
    },
    {
      loginType: LoginType.Redirect,
      //     // When a token is refreshed it will be done by loading a page in an iframe.
      //     // Rather than reloading the same page, we can point to an empty html file which will prevent
      //     // site resources from being loaded twice.
      //     tokenRefreshUri: window.location.origin + "/auth.html"
    }
  );
};
export default getAll().then((r) => r);
