import React, { useEffect, useRef, useMemo, useState } from "react";
import { Easing } from "react-native-web";
import Fly from "react-flying-objects";
import styles from "./Main.module.css";
import io from "socket.io-client";

import { getCurrentUser } from "../../../services/authService";
import axios from "axios";
const ENDPOINT = process.env.REACT_APP_SOCKET_IO_URL;
let socket;
let socketID;
const DELAY = 500;
const DURATION = 10000;
const SIZE = 50;

const random = (min, max) => Math.floor(Math.random() * (max - min) + min);
function Main() {
  const [name, setName] = useState("");

  const [userName, setUserName] = useState();
  const [room, setRoom] = useState("WebAR");
  const [eventName, setEventName] = useState("WebAR");
  const [count, setCount] = React.useState(0);
  const [smilecount, setsmileCount] = React.useState(0);
  const [laughcount, setLaughCount] = React.useState(0);
  const [likecount, setlikeCount] = React.useState(0);
  const [celebratecount, setCelebrateCount] = React.useState(0);
  const [heartcount, setHeartCount] = React.useState(0);
  let countref = useRef();
  const [animatedEmoji, setAnimatedEmoji] = useState(); // To randomly set values in the objectConfig
  const [flyingObjects, setFlyingObjects] = useState([]); // Used to manage all flying currently objects by the Fly component
  const [agree, setAgree] = useState(false);
  const onSelect = (emojiData) => {
    console.log(emojiData);
    const emoji = (
      <span
        style={{ fontSize: "24px" }}
        dangerouslySetInnerHTML={{ __html: emojiData }}
      ></span>
    );
    setAnimatedEmoji(emoji); // Setting the object which would fly in a moment
  };

  const objectConfig = useMemo(
    () => ({
      // Config for a single flying object which would fly in a moment
      right: {
        fromValue: random(100, 500),
        toValue: random(100, 500),
        duration: DURATION,
        delay: DELAY,
      },
      top: {
        fromValue: 400,
        toValue: 0,
        duration: DURATION,
        easing: Easing.elastic(5),
        delay: DELAY,
      },
      width: {
        fromValue: random(SIZE - 10, SIZE + 10),
        toValue: SIZE,
        duration: DURATION,
        easing: Easing.elastic(5),
        delay: DELAY,
      },
      height: {
        fromValue: random(SIZE - 10, SIZE + 10),
        toValue: 1000,
        duration: DURATION,
        easing: Easing.elastic(5),
        delay: DELAY,
      },
      opacity: {
        fromValue: 1,
        toValue: 0,
        duration: DURATION,
        easing: Easing.exp,
        delay: DELAY,
      },
    }),
    [animatedEmoji]
  ); // On animatedEmoji change we calculate new random values for the next flying object
  useEffect(() => {
    socket = io(ENDPOINT, {
      path: "/socket/",
    });

    getCurrentUser().then((r) => {
      setName(r.preferred_username.split(".")[0]);
      setUserName(r.preferred_username);

      socket.on("connect", function () {
        // console.log("Connected! ID: " + socket.id);

        socketID = socket.id;
      });
      setRoom("WebAR");
      setName(name);
      setEventName("WebAR");
      socket.emit(
        "join",
        {
          name: r.preferred_username.split(".")[0],
          userName: r.preferred_username,
          room: "webAR",
          eventName: "WebAR",
        },
        (error) => {
          // console.log("join error", error);
          localStorage.setItem("chatSocket", socketID);
          if (error) {
            alert(error);
          }
        }
      );
    });
    // var toggleEl = document.querySelector("#button");
    // toggleEl.addEventListener("click", function (evt) {
    //   toggleEl.emit("clicked");
    //   ;
    // });
    return function () {
      if (socket.readyState === 1) {
        // <-- This is important
        socket.close();
      }
    };
  }, [ENDPOINT, localStorage.getItem("chatSocket")]);
  function isMyScriptLoaded(url) {
    var scripts = document.getElementsByTagName("script");
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }
  const [showSurvey, setShowSurvey] = React.useState(false);
  useEffect(() => {
    if (
      !isMyScriptLoaded(
        "https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js"
      )
    ) {
      const script = document.createElement("script");
      script.src =
        "https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js";
      script.async = true;
      document.head.appendChild(script);
    }
    if (
      !isMyScriptLoaded(
        "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js"
      )
    ) {
      const script2 = document.createElement("script");
      script2.src =
        "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar-nft.js";
      script2.async = true;
      document.head.appendChild(script2);
    }
    socket.on("showSurvey", (surveyvalue) => {
      setShowSurvey(surveyvalue);
    });
    socket.on("roomData", ({ users }) => {
      setCount(users.length);
    });
    socket.on("emojis", function (emojis) {
      for (let index = 0; index < emojis.length; index++) {
        const element = emojis[index];
        if (element.name == "smile") {
          setsmileCount(element.count);
        } else if (element.name == "heartFace") {
          setLaughCount(element.count);
        } else if (element.name == "celebrate") {
          setCelebrateCount(element.count);
        } else if (element.name == "heart") {
          setHeartCount(element.count);
        } else if (element.name == "like") {
          setlikeCount(element.count);
        }
      }
    });
    socket.on("emojiget", function (eName) {
      if (eName == "smile") {
        onSelect("&#128512;");
      } else if (eName == "heartFace") {
        onSelect("&#128525;");
      } else if (eName == "celebrate") {
        onSelect("&#127881;");
      } else if (eName == "heart") {
        onSelect("&#128147;");
      } else {
        onSelect("&#128077;");
      }
    });
  }, []);
  const addCount = (eName) => {
    socket.emit("emojiadd", { eName, room }, (error) => {
      // console.log("join error", error);

      if (error) {
        alert(error);
      }
    });
  };
  const closeBtn = React.createRef();
  const saveServey = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}survey`, {
        userName,
        agreement: agree,
      })
      .then(() => {});
  };
  function playVideos() {
    var video = document.getElementById("webcam_stream");
    video.play();
  }
  return (
    <>
      <div className={styles["modal"]}>
        <button
          type='button'
          class='btn btn-dark'
          onClick={() => {
            playVideos();
          }}
        >
          Start video
        </button>
        {showSurvey ? (
          <>
            <button
              type='button'
              class='btn btn-dark'
              data-toggle='modal'
              data-target='#exampleModal'
            >
              Start Survey
            </button>

            <div
              class='modal fade'
              id='exampleModal'
              tabindex='-1'
              aria-labelledby='exampleModalLabel'
              aria-hidden='true'
            >
              <div class='modal-dialog'>
                <div class='modal-content'>
                  <div class='modal-header'>
                    <h5 class='modal-title' id='exampleModalLabel'>
                      Survey
                    </h5>
                    <button
                      type='button'
                      class='close'
                      data-dismiss='modal'
                      aria-label='Close'
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div class='modal-body'>
                    <div className={styles["survey-body"]}>
                      {" "}
                      How likely are you to recommend us?
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          checked={agree == true}
                          type='radio'
                          name='flexRadioDefault'
                          id='flexRadioDefault2'
                          value={true}
                          onChange={(e) => {
                            console.log(e.target);
                            setAgree(true);
                          }}
                        />
                        <label
                          className={styles["radio"] + " form-check-label"}
                          for='flexRadioDefault2'
                        >
                          High Likely
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='radio'
                          name='flexRadioDefault'
                          id='flexRadioDefault1'
                          checked={agree == false}
                          value={false}
                          onChange={(e) => {
                            setAgree(false);
                          }}
                        />
                        <label
                          className={styles["radio"] + " form-check-label"}
                          for='flexRadioDefault1'
                        >
                          Not Likely
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='modal-footer'>
                    <button
                      type='button'
                      class='btn btn-primary'
                      data-dismiss='modal'
                      onClick={saveServey}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className={styles["page-content"]}>
        <div className={styles["viewers"]}>
          <i class='fa fa-eye'></i>
          <div id='count' ref={countref}>
            {count}
          </div>
        </div>
      </div>
      <div
        className={styles["page-content"]}
        style={{ width: "100vw", height: "100vh" }}
      >
        <Fly
          objectToFly={animatedEmoji}
          objectConfig={objectConfig}
          flyingObjects={flyingObjects}
          setFlyingObjects={setFlyingObjects}
        />
      </div>
      <a-scene
        vr-mode-ui='enabled: false'
        arjs='sourceType: webcam; videoTexture: true; debugUIEnabled: false;'
      >
        <a-assets>
          {/* <video
            id='example-video'
            width='960'
            height='540'
            class='video-js vjs-default-skin'
            controls
            muted
            loop
            autoPlay
            preload='auto'
            src='/assets/summit/summit-catalogue.m4v'
          ></video> */}
          <video id='webcam_stream'></video>
        </a-assets>

        <a-video
          position='-0.25 1.125 -20'
          scale='20 10 1'
          src='#webcam_stream'
          hls_stream
        ></a-video>
        <a-entity
          id='model'
          position='-5.75 -12 -24'
          scale='3.5 3.5 1'
          gltf-model='url(/assets/Stage.glb)'
          animation-mixer='clip:*;loop: infinity;'
        ></a-entity>
        <a-light type='ambient' color='#ffffff'></a-light>
        <a-entity
          light='type:directional; 
                   shadowCameraVisible: false; '
          position='1 35 1'
        ></a-entity>

        <a-camera rotation-reader> </a-camera>
      </a-scene>
      <div className={styles["emojis-conent"]}>
        <div className={styles["emojis"]}>
          <div
            className={styles["emoji"]}
            onClick={() => {
              addCount("smile");
            }}
          >
            <span style={{ fontSize: "24px" }}>&#128512;</span>
            <div id='count' className={styles["count"]}>
              {smilecount}
            </div>
          </div>
          <div
            className={styles["emoji"]}
            onClick={() => {
              addCount("heartFace");
            }}
          >
            <span style={{ fontSize: "24px" }}>&#128525;</span>
            <div id='count' className={styles["count"]}>
              {laughcount}
            </div>
          </div>
          <div
            className={styles["emoji"]}
            onClick={() => {
              addCount("celebrate");
            }}
          >
            <span style={{ fontSize: "24px" }}>&#127881;</span>
            <div id='count' className={styles["count"]}>
              {celebratecount}
            </div>
          </div>
          <div
            className={styles["emoji"]}
            onClick={() => {
              addCount("heart");
            }}
          >
            <span style={{ fontSize: "24px" }}>&#128147;</span>
            <div id='count' className={styles["count"]}>
              {heartcount}
            </div>
          </div>
          <div
            className={styles["emoji-last"]}
            onClick={() => {
              addCount("like");
            }}
          >
            <span style={{ fontSize: "24px" }}>&#128077;</span>
            <div id='count' className={styles["count"]}>
              {likecount}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
