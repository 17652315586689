import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../services/authService";
import styles from "./HRComingSoon.module.css";
function HRComingSoon() {
  const history = useNavigate();
  React.useEffect(() => {
    localStorage.setItem(
      "selectedEventName",
      window.location.href.split("//")[1].split("/")[1]
    );

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}projects/getprojectbypath`, {
        path: window.location.href,
      })
      .then((res) => {
        if (res.data._id !== undefined) {
          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}stats/setpage/HRComingSoon`,
              {
                id: res.data._id,
              }
            )
            .then((res) => {});
        }
      });
  }, []);
  return (
    <div className='container-fluid '>
      <div className=' row'>
        <div
          className={styles["btn-back-envirtual-container"] + " col-md-12"}
          onClick={() => {
            history("/showroom");
          }}
        >
          <img src='/assets/back-btn.svg' className={styles["ddl"]}></img>
          <label className={styles["back-btn-label"]}>Back</label>
        </div>
        <img
          src='/assets/showroom/mainhall/ic-bg14.png'
          className='mentor-card-screen-background'
          alt=''
        />
        <div className='col-md-3'></div>

        <div className='col-md-6 p-5 col-sm-12'>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className={styles["text-shadow"]} style={{ color: "white" }}>
              Coming Soon
            </h1>
          </div>
        </div>

        <div className='col-md-3'></div>
      </div>
    </div>
  );
}

export default HRComingSoon;
