import React, { useState, useEffect, useContext } from "react";
import "./projectScreen.css";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import FlippingCard from "../../flippingCard/flippingCard";
import { authContext } from "../../../contextGenerator";
import axios from "axios";
import { getCurrentUser } from "../../../services/authService";
function ProjectScreen() {
  const [data, setdata] = useState([]);
  const context = useContext(authContext);
  const history = useNavigate();

  useEffect(() => {
    if (data.length == 0) {
      getCurrentUser().then((r) => {
        debugger;
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}auth`, {
            email: r.preferred_username,
          })
          .then((x) => {
            if (x.data == "true") {
              context.role = "SuperAdmin";
              history("/projects");
            } else {
              debugger;
              axios
                .post(
                  `${process.env.REACT_APP_SERVER_URL}projects/getbyEmail`,
                  {
                    email: r.preferred_username,
                  }
                )
                .then((resp) => {
                  resp.data.forEach((element) => {
                    debugger;
                    if (
                      data.filter((r) => r.project.name == element.project.name)
                        .length == 0
                    ) {
                      {
                        let x = data;
                        x.push(element);
                        setdata(x);
                      }
                    }
                  });
                });
            }
          });
      });
    }
  }, []);
  return (
    <div className='maindiv row'>
      <img
        src='/assets/background.png'
        className='mentor-card-screen-background'
        alt=''
      />

      <div className='col-md-12 mt-5' style={{ position: "relative" }}>
        <div className='row'>
          {data
            ? data.map((el) => {
                return (
                  <FlippingCard
                    cardData={el}
                    fromSuperAdmin={false}
                    isAdmin={el.IsAdmin}
                  ></FlippingCard>
                );
                console.log(el);
              })
            : null}
        </div>
      </div>
      <img
        className='logoContainer mb-2'
        alt='login-background'
        src='/assets/ic-logo-navbar.png'
      ></img>
    </div>
  );
}

export default ProjectScreen;
