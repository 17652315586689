import React from "react";

import onlineIcon from "../../icons/onlineIcon.png";

import "./TextContainer.css";

const TextContainer = ({ users }) => (
  <div className='textContainer'>
    {users ? (
      <div>
        {/* {console.log(users)} */}
        <h4 className='mt-3'>Active now</h4>
        <div className='activeContainer'>
          <h5>
            {users.map(({ name }) => (
              <div key={Math.random()} className='activeItem'>
                {name}
                <img alt='Online Icon' src={onlineIcon} />
              </div>
            ))}
          </h5>
        </div>
      </div>
    ) : null}
  </div>
);

export default TextContainer;
