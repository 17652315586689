import React, { useEffect, useState } from "react";
import WivaNavBar from "../WIVANavBar/WivaNavBar";
import WIVASideNavBar from "../WIVASideNavBar/WIVASideNavBar";
import styles from "./WIVAHome2.module.css";
import { isMobile } from "react-device-detect";
function WIVAHome2() {
  const [loading, setloading] = useState(true);
  const [loadingCount, setloadingCount] = useState(0);
  useEffect(() => {
    if (!isMobile)
      if (
        window.localStorage.getItem("firsttime2") == "true" ||
        window.localStorage.getItem("firsttime2") == null ||
        window.localStorage.getItem("firsttime2") == undefined
      ) {
        document.getElementById("wivawelcomebtn").click();
      } else {
        if (document.getElementById("iframe2")) {
          document.getElementById("iframe2").focus();
        }
      }
  }, []);
  let interval;
  useEffect(() => {
    if (!isMobile)
      if (
        window.localStorage.getItem("firsttime2") == "true" ||
        window.localStorage.getItem("firsttime2") == null ||
        window.localStorage.getItem("firsttime2") == undefined
      ) {
        interval = setInterval(() => {
          let count = loadingCount;
          if (loadingCount < 100) {
            {
              setloadingCount(parseInt(count) + 1);

              if (loadingCount >= 20 && loadingCount < 40) {
                document.getElementById("loadingfirstsection").className =
                  styles["first-section"];
                document.getElementById("loadingsecondsection").className =
                  styles["other-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["other-section"];

                document.getElementById("loadingfourthsection").className =
                  styles["other-section"];
                document.getElementById("loadingbeforefirstsection").className =
                  styles["other-section"];
              }
              if (loadingCount >= 40 && loadingCount < 60) {
                document.getElementById("loadingsecondsection").className =
                  styles["first-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["other-section"];
                document.getElementById("loadingfirstsection").style.display =
                  "none";
                document.getElementById("loadingfourthsection").className =
                  styles["other-section"];
              }
              if (loadingCount >= 60 && loadingCount < 80) {
                document.getElementById("loadingsecondsection").className =
                  styles["other-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["first-section"];
                document.getElementById("loadingfirstsection").className =
                  styles["other-section"];
                document.getElementById("loadingfourthsection").className =
                  styles["other-section"];
              }
              if (loadingCount >= 80) {
                document.getElementById("loadingsecondsection").className =
                  styles["other-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["other-section"];
                document.getElementById("loadingfirstsection").className =
                  styles["other-section"];
                document.getElementById("loadingfourthsection").className =
                  styles["first-section"];
              }
            }
          } else {
            setloading(false);
            //   window.localStorage.setItem("firsttime2", "false");
          }
        }, 600);
        return () => {
          clearInterval(interval);
        };
      } else {
        interval = setInterval(() => {
          let count = loadingCount;
          if (loadingCount < 100) {
            {
              setloadingCount(parseInt(count) + 1);

              if (loadingCount >= 20 && loadingCount < 40) {
                document.getElementById("loadingfirstsection").className =
                  styles["first-section"];
                document.getElementById("loadingsecondsection").className =
                  styles["other-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["other-section"];

                document.getElementById("loadingfourthsection").className =
                  styles["other-section"];
                document.getElementById("loadingbeforefirstsection").className =
                  styles["other-section"];
              }
              if (loadingCount >= 40 && loadingCount < 60) {
                document.getElementById("loadingsecondsection").className =
                  styles["first-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["other-section"];
                document.getElementById("loadingfirstsection").style.display =
                  "none";
                document.getElementById("loadingfourthsection").className =
                  styles["other-section"];
              }
              if (loadingCount >= 60 && loadingCount < 80) {
                document.getElementById("loadingsecondsection").className =
                  styles["other-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["first-section"];
                document.getElementById("loadingfirstsection").className =
                  styles["other-section"];
                document.getElementById("loadingfourthsection").className =
                  styles["other-section"];
              }
              if (loadingCount >= 80) {
                document.getElementById("loadingsecondsection").className =
                  styles["other-section"];
                document.getElementById("loadingthirdsection").className =
                  styles["other-section"];
                document.getElementById("loadingfirstsection").className =
                  styles["other-section"];
                document.getElementById("loadingfourthsection").className =
                  styles["first-section"];
              }
            }
          } else {
            setloading(false);
            //   window.localStorage.setItem("firsttime2", "false");
          }
        }, 200);
        return () => {
          clearInterval(interval);
        };
      }
  }, [loadingCount]);
  if (isMobile) {
    return (
      <div className={styles["mobile-bg"]}>
        <div className={styles["mobile-bg-div"]}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='86'
            height='23'
            viewBox='0 0 86 23'
            fill='none'
          >
            <g filter='url(#filter0_f_468_3830)'>
              <path
                d='M21.7584 20.1164L15.4141 1.39331H17.0423L22.544 18.1236L28.271 1.39331H29.7583L23.2192 20.1164H21.7584Z'
                fill='white'
              />
              <path
                d='M50.7276 10.741C50.7287 11.9472 50.5826 13.1491 50.2925 14.3199C50.03 15.425 49.584 16.4783 48.9731 17.4359C48.396 18.3317 47.6236 19.0851 46.7137 19.6397C45.7296 20.1895 44.6216 20.4793 43.4944 20.4818C42.3672 20.4842 41.2579 20.1992 40.2715 19.6536C39.3622 19.0999 38.5862 18.3523 37.9991 17.4642C37.3715 16.5122 36.9155 15.4576 36.6519 14.3482C36.3603 13.168 36.2142 11.9566 36.2168 10.741C36.2135 9.51424 36.3694 8.29222 36.6806 7.10557C36.9601 6.00129 37.425 4.95249 38.0557 4.00392C38.6439 3.12417 39.4201 2.38589 40.3281 1.84231C41.2863 1.28702 42.3787 1.00594 43.4859 1.0298C44.6178 1.00299 45.735 1.28964 46.7141 1.85808C47.6246 2.404 48.3974 3.15201 48.9727 4.04426C49.5835 5.00194 50.0294 6.0552 50.2921 7.16029C50.5821 8.33112 50.7282 9.53295 50.7271 10.7392M49.211 10.7392C49.211 9.72863 49.098 8.72128 48.8744 7.73582C48.6706 6.79788 48.3202 5.89794 47.836 5.06918C47.3888 4.30598 46.7802 3.64975 46.0528 3.14642C45.2938 2.63985 44.3967 2.38065 43.4845 2.40439C42.5697 2.38218 41.6696 2.6366 40.9018 3.13436C40.1637 3.62943 39.5491 4.28722 39.1052 5.05712C38.6221 5.89157 38.2716 6.79604 38.0663 7.73814C37.8425 8.72358 37.7296 9.73095 37.7296 10.7415C37.7299 11.7575 37.8476 12.7701 38.0803 13.7592C38.2931 14.7078 38.653 15.6172 39.1469 16.4546C39.597 17.2194 40.2109 17.8751 40.9445 18.3746C41.6972 18.8709 42.5835 19.1256 43.485 19.1045C44.3881 19.1257 45.2757 18.8665 46.0255 18.3625C46.7573 17.8548 47.3702 17.1943 47.8221 16.4268C48.3137 15.5949 48.6691 14.6898 48.8748 13.7457C49.0985 12.7603 49.2115 11.7529 49.2115 10.7424'
                fill='white'
              />
              <path
                d='M63.3882 1.39331H59.7949V20.1164H63.3882V1.39331Z'
                fill='white'
              />
              <path
                d='M10.9426 20.3975H1V21.8699H10.9426V20.3975Z'
                fill='white'
              />
              <path
                d='M78.2652 20.5096C79.1597 20.4899 80.0473 20.3482 80.9035 20.0885C81.941 19.7777 82.8606 19.1613 83.5423 18.3197C84.3082 17.388 84.7162 16.2134 84.6929 15.0075C84.682 13.9359 84.4098 12.8832 83.8999 11.9407C82.8796 10.2734 80.9318 9.50358 79.163 8.88816C78.5382 8.676 77.9245 8.43253 77.3242 8.15866C76.949 7.99062 76.5876 7.79354 76.2431 7.56922C76.0183 7.39489 75.8395 7.16816 75.7224 6.90882C75.6054 6.64948 75.5536 6.36543 75.5716 6.08146C75.5678 5.82128 75.6182 5.56315 75.7198 5.32359C75.8214 5.08402 75.9718 4.86828 76.1615 4.69017C76.5545 4.30679 77.2375 4.11495 78.2104 4.11464C78.581 4.1125 78.9499 4.16452 79.3054 4.26907C79.9247 4.44093 80.5262 4.67122 81.102 4.95684C81.7009 5.21535 82.2443 5.58696 82.7025 6.05132L84.583 3.55349C84.1938 3.14244 83.7606 2.77527 83.2914 2.45854C82.6305 2.00814 81.9062 1.65865 81.1423 1.42156C80.2399 1.13292 79.297 0.990751 78.3496 1.00047C77.3192 1.00071 76.2956 1.16653 75.3179 1.49159C74.3575 1.79549 73.5271 2.41338 72.9602 3.24601C72.3592 4.11917 72.0542 5.16213 72.0901 6.22152C72.0319 7.11813 72.2121 8.01417 72.6123 8.8186C73.5055 10.4696 75.33 11.2895 77.058 11.7797C78.7235 12.3597 79.8277 12.8744 80.3706 13.3236C80.6266 13.5508 80.8279 13.833 80.9592 14.149C81.0905 14.4651 81.1485 14.8068 81.1289 15.1485C81.1375 15.4536 81.0717 15.7561 80.9371 16.03C80.8026 16.3039 80.6034 16.541 80.3567 16.7207C79.7346 17.1455 78.9895 17.3527 78.2373 17.3101C77.6661 17.3155 77.0961 17.2543 76.539 17.1279C75.6712 16.9308 74.8478 16.5738 74.1108 16.0751C73.8663 15.9116 73.632 15.7335 73.4091 15.5418L71.8926 18.6578C72.1476 18.844 72.4148 19.0127 72.6926 19.1628C73.1292 19.4007 73.5846 19.6023 74.0542 19.7657C74.6683 19.9822 75.2966 20.1557 75.9347 20.2852C76.7014 20.4402 77.4821 20.5154 78.2642 20.5096'
                fill='white'
              />
            </g>
            <defs>
              <filter
                id='filter0_f_468_3830'
                x='0'
                y='0'
                width='85.6934'
                height='22.8699'
                filterUnits='userSpaceOnUse'
                color-interpolation-filters='sRGB'
              >
                <feFlood flood-opacity='0' result='BackgroundImageFix' />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='BackgroundImageFix'
                  result='shape'
                />
                <feGaussianBlur
                  stdDeviation='0.5'
                  result='effect1_foregroundBlur_468_3830'
                />
              </filter>
            </defs>
          </svg>
          <img
            src='/wiv/WIVAlogoWhite.png'
            className={styles["main-header-img"]}
          ></img>
          <span className={styles["mobile-bg-text"]}>
            To ensure a smooth 3D experience, please use your laptop or desktop
            to explore our 3d environment.
          </span>
        </div>
        <img
          className={styles["img-disclaimer"]}
          src='/wiv/mobile-view-disclaimer.png'
        ></img>
      </div>
    );
  } else {
    return (
      <div className={styles["container-fluid"] + " "}>
        <div className={styles["row"] + " row"}>
          <div className='col-md-12 p-0'>
            {loading ? (
              <>
                <div className={styles["wiv-joinOuterContainer"]}>
                  {window.localStorage.getItem("firsttime") == "false" ? (
                    <div className={styles["header-div"]}>
                      <div
                        className={styles["skipDiv"]}
                        onClick={() => {
                          // window.localStorage.setItem("firsttime", "false");

                          setloadingCount(100);
                        }}
                      >
                        <span className={styles["skip-text"]}>SKIP</span>
                        <img src='/earth/skip-arrow.svg'></img>
                      </div>
                    </div>
                  ) : null}
                  <div className={styles["loading-bar-div"]}>
                    <span className={styles["main-text"]}>
                      {" "}
                      Welcome to Women in _VO
                      <span className={styles["main-text-bold"]}>IS</span> Arena
                      (WIVA)
                    </span>

                    <label className={styles["sub-main-text"]}>
                      Loading .. {loadingCount} %
                    </label>

                    <div
                      class='progress mt-2'
                      style={{
                        width: "80%",
                        height: "0.5rem",
                        backgroundColor: "#CCCCCC",
                      }}
                    >
                      <div
                        class='progress-bar'
                        role='progressbar'
                        style={{
                          width: `${loadingCount}%`,
                          backgroundColor: "#BE0000",
                          fontFamily: "Polaris !important",
                        }}
                        aria-valuenow={`${loadingCount}`}
                        aria-valuemin='0'
                        aria-valuemax='100'
                      ></div>
                    </div>
                  </div>
                  {isMobile ? (
                    <div
                      id='loadingbeforefirstsection'
                      className={styles["first-section"]}
                    >
                      <img
                        src='/wiv/InstructionNavigate.svg'
                        className={styles["img"]}
                      ></img>
                      <span className={styles["wiv-tip-text"]}>
                        Navigate around the hall by dragging{" "}
                        <span className={styles["wiv-tip-text-bolder"]}>
                          The Arrows{" "}
                        </span>
                        button all around.
                      </span>
                    </div>
                  ) : (
                    <div
                      id='loadingbeforefirstsection'
                      className={styles["first-section"]}
                    >
                      <img
                        src='/wiv/instructionmouse0.svg'
                        className={styles["img"]}
                      ></img>
                      <span className={styles["wiv-tip-text"]}>
                        Move around the 3D platform by pressing the or using{" "}
                        <span className={styles["wiv-tip-text-bolder"]}>
                          arrow keys{" "}
                        </span>
                        the{" "}
                        <span className={styles["wiv-tip-text-bolder"]}>
                          WASD
                        </span>{" "}
                        keys.
                      </span>
                    </div>
                  )}
                  {isMobile ? (
                    <div
                      id='loadingfirstsection'
                      className={styles["other-section"]}
                    >
                      <img
                        src='/wiv/InstructionRotate.svg'
                        className={styles["img"]}
                      ></img>
                      <span className={styles["wiv-tip-text"]}>
                        Explore event environment by dragging
                        <span className={styles["wiv-tip-text-bolder"]}>
                          {" "}
                          Right and left
                        </span>{" "}
                        to navigate.
                      </span>
                    </div>
                  ) : (
                    <div
                      id='loadingfirstsection'
                      className={styles["other-section"]}
                    >
                      <img
                        src='/wiv/instructionmouse2.svg'
                        className={styles["img"]}
                      ></img>
                      <span className={styles["wiv-tip-text"]}>
                        Explore information about any clickable link by
                        <span className={styles["wiv-tip-text-bolder"]}>
                          {" "}
                          left-clicking
                        </span>{" "}
                        on them.
                      </span>
                    </div>
                  )}{" "}
                  {isMobile ? (
                    <div
                      id='loadingsecondsection'
                      className={styles["other-section"]}
                    >
                      <img
                        src='/wiv/instructionTouch.svg'
                        className={styles["img"]}
                      ></img>
                      <span className={styles["wiv-tip-text"]}>
                        Explore information about any clickable link by{" "}
                        <span className={styles["wiv-tip-text-bolder"]}>
                          Long Press Touch
                        </span>{" "}
                        on them.
                      </span>
                    </div>
                  ) : (
                    <div
                      id='loadingsecondsection'
                      className={styles["other-section"]}
                    >
                      <img src='/wiv/Mouse.svg' className={styles["img"]}></img>
                      <span className={styles["wiv-tip-text"]}>
                        Explore the 3D platform by rotating and looking around
                        using the{" "}
                        <span className={styles["wiv-tip-text-bolder"]}>
                          left-clicking and drag
                        </span>
                        .
                      </span>
                    </div>
                  )}
                  <div
                    id='loadingthirdsection'
                    className={styles["other-section"]}
                  >
                    <img
                      src='/wiv/instructionmouse3.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Access detailed information by{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        clicking
                      </span>{" "}
                      the information icon.
                    </span>
                  </div>
                  <div
                    id='loadingfourthsection'
                    className={styles["other-section"]}
                  >
                    <img
                      src='/wiv/instructionmouse4.svg'
                      className={styles["img"]}
                    ></img>
                    <span className={styles["wiv-tip-text"]}>
                      Navigate to any location on the event by{" "}
                      <span className={styles["wiv-tip-text-bolder"]}>
                        clicking on the map icon
                      </span>
                      .
                    </span>
                  </div>
                  <div className={styles["loading-footer"]}>
                    <label className={styles["footer-text"]}>
                      {" "}
                      For a better experience, make sure you have a stable
                      internet connection and aren’t connected to the VPN.
                    </label>
                    <label className={styles["footer-text"]}>
                      {" "}
                      If you face technical issues, please contact us via
                      <a
                        href='mailto:envirtual@vodafone.com'
                        target='_blank'
                        style={{ color: "#e60000" }}
                      >
                        {" "}
                        envirtual@vodafone.com
                      </a>
                      .
                    </label>
                    <label className={styles["footer-bold-main-text"]}>
                      © Brought to you by
                    </label>

                    <label className={styles["footer-text"]}>
                      <a
                        href='https://envirtual.tvois.vodafone.com/showroom'
                        target='_blank'
                        style={{ color: "#e60000" }}
                      >
                        Tech _VO<strong>IS</strong> - IT Platforms & Automation
                        GSL
                      </a>
                    </label>
                  </div>
                </div>
              </>
            ) : null}
            <>
              <WivaNavBar></WivaNavBar>
              <WIVASideNavBar></WIVASideNavBar>

              <iframe
                scrolling='no'
                id='iframe2'
                src='/wiva/wivBuildnew2/index.html'
                className={styles["iframe"]}
              ></iframe>
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default WIVAHome2;
